import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BsArrowLeft, BsPlus } from "react-icons/bs";
import { Oval } from "react-loader-spinner"; // Importa una animación de carga de tu elección

const AddBaner = () => {
  const [descripcion, setDescripcion] = useState("");
  const [photo, setPhoto] = useState(null);
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(false); // Nuevo estado para la animación
  const navigate = useNavigate();

  const handlePhotoChange = (e) => {
    // Actualizar el estado de la imagen y la vista previa al seleccionar un archivo
    const selectedPhoto = e.target.files[0];
    setPhoto(selectedPhoto);
    if (selectedPhoto) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(selectedPhoto);
    } else {
      setPreview(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Inicia la animación de carga

    try {
      const formData = new FormData();
      formData.append("descripcion", descripcion);
      formData.append("photo", photo);

      const response = await axios.post(
        `${window.BACKEND_URL}baner`,
        formData,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
            "Content-Type": "application/json",
            "X-Rol": `${sessionStorage.getItem("role")}`, 
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 201) {
        setDescripcion("");
        setPhoto(null);
        setPreview(null);
        alert("Baner agregado correctamente");
        navigate("/Baner");
      }
    } catch (error) {
      console.error("Error al agregar el baner:", error);
      alert("Error al agregar el baner");
    } finally {
      setLoading(false); // Detiene la animación de carga después de la operación
    }
  };

  const Baner = () => {
    navigate("/Baner"); // Asegúrate de que la ruta coincida con tu configuración en App.js
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title mb-0">Agregar Baner</h3>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="descripcion" className="form-label">
                  Link:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="descripcion"
                  value={descripcion}
                  onChange={(e) => setDescripcion(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="photo" className="form-label">
                  Foto:
                </label>
                <input
                  type="file"
                  className="form-control"
                  id="photo"
                  onChange={handlePhotoChange}
                  required
                />
              </div>
              {preview && (
                <img
                  src={preview}
                  alt="Preview"
                  style={{ maxWidth: "100%", height: "auto" }}
                  type="file"
                  accept="image/*"
                />
              )}
              <div className="d-flex justify-content-between">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={Baner}
                >
                  <BsArrowLeft style={{ fontSize: "1.6em" }} /> Regresar
                </button>
                <button type="submit" className="btn btn-primary">
                  {loading ? (
                    <Oval
                      color="#00BFFF"
                      height={50}
                      width={50}
                      loading={loading}
                    />
                  ) : (
                    <>
                      Agregar Baner <BsPlus style={{ fontSize: "2em" }} />
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBaner;
