import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { BsArrowLeft, BsPencilSquare } from "react-icons/bs";
import { Oval } from "react-loader-spinner";

const EditMatricula = () => {
  const [matriculas, setMatriculas] = useState({
    alumno_id: "",
    curso_id: "",
    notaFinal: 0,
  });
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [alumnos, setAlumnos] = useState([]);
  const [cursos, setCursos] = useState([]);

  useEffect(() => {
    const fetchMatricula = async () => {
      try {
        const response = await fetch(`${window.BACKEND_URL}matricula/${id}`, {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
            "X-Rol": `${sessionStorage.getItem("role")}`,
          },
        });
        if (!response.ok) {
          throw new Error("Error al obtener la matricula");
        }
        const data = await response.json();
        setMatriculas(data);
      } catch (error) {
        console.error("Error fetching matricula:", error);
      }
    };

    const fetchCurso = async () => {
      try {
        const response = await fetch(`${window.BACKEND_URL}curso`, {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
            "X-Rol": `${sessionStorage.getItem("role")}`,
          },
        });
        if (!response.ok) {
          throw new Error("Error al obtener el curso");
        }
        const cursoData = await response.json();
        setCursos(cursoData);
      } catch (error) {
        console.error("Error al obtener el curso:", error);
        // Manejar el error, por ejemplo, mostrar un mensaje al usuario
      }
    };

    const fetchAlumnos = async () => {
      try {
        const response = await fetch(`${window.BACKEND_URL}alumno`, {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
            "X-Rol": `${sessionStorage.getItem("role")}`,
          },
        });
        if (!response.ok) {
          throw new Error("Error al obtener los alumnos");
        }
        const data = await response.json();
        setAlumnos(data);
      } catch (error) {
        console.error("Error fetching alumnos:", error);
      }
    };

    fetchMatricula();
    fetchAlumnos();
    fetchCurso();

    // Limpiar efecto si es necesario
    return () => {
      // Agregar código de limpieza si es necesario, por ejemplo, cancelar la solicitud
    };
  }, [id]);

  const handleInputChange = (e) => {
    setMatriculas({ ...matriculas, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      const response = await fetch(`${window.BACKEND_URL}matricula/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(matriculas),
      });

      if (!response.ok) {
        const errorMessage = await response.text();
        console.error("Error al actualizar la matricula:", errorMessage);
      } else {
        navigate("/Matricula"); // Redireccionar después de la actualización exitosa
      }
    } catch (error) {
      console.error("Error al enviar la solicitud:", error);
    } finally {
      setLoading(false);
    }
  };

  const Curso = () => {
    navigate("/Matricula");
  };
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title mb-0">Editar Matricula</h3>
            </div>

            <hr />
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="alumno" className="form-label">
                    Alumno:
                  </label>
                  <select
                    className="form-select"
                    name="alumno_id"
                    value={matriculas.alumno_id}
                    onChange={handleInputChange}
                  >
                    <option disabled value="">
                      Selecciona un alumno:
                    </option>
                    {alumnos.map((alumno) => (
                      <option key={alumno._id} value={alumno._id}>
                        {alumno.Nombre}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="mb-3">
                  <label htmlFor="curso" className="form-label">
                    Curso:
                  </label>
                  <select
                    className="form-select"
                    name="curso_id"
                    value={matriculas.curso_id}
                    onChange={handleInputChange}
                  >
                    <option disabled value="">
                      Selecciona un Curso:
                    </option>
                    {cursos.map((curso) => (
                      <option key={curso._id} value={curso._id}>
                        {curso.nombre}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="mb-3">
                  <label htmlFor="descripcion" className="form-label">
                    Nota Final:
                  </label>
                  <input
                    type="number"
                    name="notaFinal"
                    id="notaFinal"
                    className="form-control"
                    value={matriculas.notaFinal}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="d-flex justify-content-between">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={Curso}
                  >
                    <BsArrowLeft style={{ fontSize: "1.6em" }} /> Regresar
                  </button>
                  <button type="submit" className="btn btn-primary">
                    {loading ? (
                      <>
                        <Oval color="#00BFFF" height={40} width={40} />{" "}
                      </>
                    ) : (
                      <>
                        <BsPencilSquare /> Editar Matricula
                      </>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditMatricula;
