import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { BsPencilSquare, BsTrash3Fill } from "react-icons/bs";
import $ from "jquery";
import LoadingOverlay from "../components/LoadingOverlay";

const Colabora = () => {
  const navigate = useNavigate();
  const [colaboradores, setColaboradores] = useState([]);
  const [loading, setLoading] = useState(true);

  const getColaboradores = async () => {
    try {
      const response = await axios.get(`${window.BACKEND_URL}colaboradores`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
          "Content-Type": "application/json",
          "X-Rol": `${sessionStorage.getItem("role")}`, // Corrección aquí
        },
      });
      setColaboradores(response.data);
    } catch (error) {
      console.error("Error al obtener los colaboradores:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await getColaboradores();
        //initializeDataTable();
      } catch (error) {
        console.error("Error al obtener los colaboradores:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const initializeDataTable = async () => {
      if (!tablacol.current || $.fn.DataTable.isDataTable(tablacol.current))
        return;

      $(tablacol.current).DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
        },
      });
    };

    initializeDataTable();
  }, [loading]);

  const tablacol = useRef(null);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${window.BACKEND_URL}colaboradores/${id}`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
          "Content-Type": "application/json",
          "X-Rol": `${sessionStorage.getItem("role")}`, // Corrección aquí
        },
      });
      getColaboradores();
      navigate("/Colabora"); // Vuelve a cargar los colaboradores después de eliminar uno
    } catch (error) {
      console.error("Error al eliminar el colaborador:", error);
    }
  };

  const EditCol = (colId) => {
    navigate(`/Editcol/${colId}`);
  };

  return (
    <div className="position-relative">
      {loading && <LoadingOverlay loading={loading} text="Cargando..." />}
      {!loading && (
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <span className="float-end">
                  <Link to="/Addcol" className="btn btn-primary">
                    <i className="fa-solid fa-plus"></i> Agregar Colaborador
                  </Link>
                </span>
                <h5 className="card-title mb-0">Colaboradores</h5>
              </div>
              <hr />

              <div className="card-body">
                <table className="table table-bordered" ref={tablacol}>
                  <thead>
                    <tr>
                      <th>Foto</th>
                      <th>Nombre</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {colaboradores.map((colaborador) => (
                      <tr key={colaborador._id}>
                        <td>
                          <img
                            src={`${window.BACKEND_URL}uploads/${colaborador.photo}`}
                            alt={colaborador.namecol}
                            style={{ width: "100px", height: "100px" }}
                          />
                        </td>
                        <td>{colaborador.namecol}</td>

                        <td>
                          <button
                            className="btn btn-primary me-2"
                            onClick={() => EditCol(colaborador._id)}
                          >
                            <BsPencilSquare /> Editar
                          </button>
                          <button
                            className="btn btn-danger"
                            onClick={() => handleDelete(colaborador._id)}
                          >
                            <BsTrash3Fill /> Eliminar
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Colabora;
