import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BsPencilSquare, BsTrash3Fill } from "react-icons/bs";
import axios from "axios";
import LoadingOverlay from "../components/LoadingOverlay";
import Moment from "react-moment";
import $ from "jquery";

const Matricula = () => {
  const navigate = useNavigate();
  const [matriculas, setMatriculas] = useState([]);
  const [loading, setLoading] = useState(true);

  const getMatriculas = async () => {
    try {
      const response = await axios.get(`${window.BACKEND_URL}matricula`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
          "X-Rol": `${sessionStorage.getItem("role")}`,
        },
      });
      setMatriculas(response.data);
    } catch (error) {
      console.error("Error al obtener las matrículas:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await getMatriculas();
      } catch (error) {
        console.error("Error al obtener las matrículas:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const initializeDataTable = async () => {
      if (
        !tablaMatriculas.current ||
        $.fn.DataTable.isDataTable(tablaMatriculas.current)
      )
        return;

      $(tablaMatriculas.current).DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
        },
      });
    };

    initializeDataTable();
  }, [loading]);

  const tablaMatriculas = useRef(null);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${window.BACKEND_URL}matricula/${id}`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
          "X-Rol": `${sessionStorage.getItem("role")}`,
        },
      });
      // Eliminar la matrícula de la lista actualizada
      setMatriculas((prevMatriculas) =>
        prevMatriculas.filter((matricula) => matricula._id !== id)
      );
    } catch (error) {
      console.error("Error al eliminar la matrícula:", error);
    }
  };

  const EditMatricula = (matriculaId) => {
    navigate(`/Editmatricula/${matriculaId}`);
  };

  return (
    <div className="position-relative">
      {loading && <LoadingOverlay loading={loading} text="Cargando..." />}
      {!loading && (
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <span className="float-end">
                  <Link to="/Addmatricula" className="btn btn-primary">
                    <i className="fa-solid fa-plus"></i> Agregar Matrícula
                  </Link>
                </span>
                <h5 className="card-title mb-0">Matrículas</h5>
              </div>
              <hr />

              <div className="card-body">
                <table className="table table-bordered" ref={tablaMatriculas}>
                  <thead>
                    <tr>
                      <th>Alumno ID</th>
                      <th>Curso ID</th>
                      <th>Nota Final</th>
                      <th>Fecha y Hora</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {matriculas.map((matricula) => (
                      <tr key={matricula._id}>
                        <td>{matricula.informacion_alumno[0]?.Nombre}</td>
                        <td>{matricula.informacion_curso[0].nombre}</td>
                        <td>
                          {matricula.notaFinal
                            ? matricula.notaFinal
                            : "(No calificado)"}
                        </td>
                        <td>
                          <table className="table table-bordered">
                            <tbody>
                              <tr>
                                <td>
                                  <strong>Fecha:</strong>{" "}
                                  <Moment format="DD-MM-YYYY">
                                    {matricula.created}
                                  </Moment>
                                </td>
                                <td>
                                  <strong>Hora:</strong>{" "}
                                  <Moment format="hh:mm:ss A">
                                    {matricula.created}
                                  </Moment>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td>
                          <button
                            className="btn btn-primary me-2"
                            onClick={() => EditMatricula(matricula._id)}
                          >
                            <BsPencilSquare /> Editar
                          </button>
                          <button
                            onClick={() => handleDelete(matricula._id)}
                            className="btn btn-danger"
                          >
                            <BsTrash3Fill /> Eliminar
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Matricula;
