import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BsPencilSquare, BsTrash3Fill } from "react-icons/bs";
import axios from "axios";
import LoadingOverlay from "../components/LoadingOverlay"; // Importa el nuevo componente
import $ from "jquery";

const Torneo = () => {
  const navigate = useNavigate();
  const [torneos, setTorneo] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const GetTorneo = async () => {
      try {
        const response = await axios.get(`${window.BACKEND_URL}torneo`, {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
            "X-Rol": sessionStorage.getItem("role"),
          },
        });
        setTorneo(response.data);
      } catch (error) {
        console.error("Error al obtener los Webinarios:", error);
      } finally {
        setLoading(false);
      }
    };
    const fetchData = async () => {
      try {
        setLoading(true);
        await GetTorneo();
      } catch (error) {
        console.error("Error al obtener los webinar:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const initializeDataTable = async () => {
      if (!tablabaner.current || $.fn.DataTable.isDataTable(tablabaner.current))
        return;

      $(tablabaner.current).DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
        },
      });
    };

    initializeDataTable();
  }, [loading]);

  const tablabaner = useRef(null);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${window.BACKEND_URL}torneo/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${sessionStorage.getItem("token")}`,
          "X-Rol": sessionStorage.getItem("role"),
        },
      });

      setTorneo((prevBaners) => prevBaners.filter((baner) => baner._id !== id));
    } catch (error) {
      console.error("Error al eliminar el baner:", error);
    }
  };

  const Ediywebinar = (torneoId) => {
    navigate(`/EditarTorneo/${torneoId}`);
  };
  const AddNovedad = (torneoId) => {
    navigate(`/AgregarTorneo/Novedad/${torneoId}`);
  };
  const Edittiktok = (torneoId) => {
    navigate(`/editar/tiktok/${torneoId}`);
  };

  return (
    <div className="position-relative">
      {loading && <LoadingOverlay loading={loading} text="Cargando..." />}
      {!loading && (
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <span className="float-end">
                  <Link to="/AgregarTorneo" className="btn btn-primary">
                    <i className="fa-solid fa-plus"></i> Agregar un Torneo
                  </Link>
                </span>
                <h5 className="card-title mb-0">Torenos </h5>
              </div>
              <hr />

              <div className="card-body">
                <table className="table table-bordered" ref={tablabaner}>
                  <thead>
                    <tr>
                      <th>Foto</th>
                      <th>Nombre</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {torneos.map((data) => (
                      <tr key={data._id}>
                        <td>
                          <img
                            src={`${window.BACKEND_URL}uploads/${data.photo}`}
                            className="w-25"
                            alt="Foto del baner"
                          />
                        </td>
                        <td>{data.Titulo}</td>
                        <td>
                          <button
                            className="btn btn-primary me-2"
                            onClick={() => Ediywebinar(data._id)}
                          >
                            <BsPencilSquare /> Editar
                          </button>
                          <button
                            className="btn btn-primary me-2"
                            onClick={() => AddNovedad(data._id)}
                          >
                            <BsPencilSquare /> Agregae Novedades
                          </button>
                          <button
                            className="btn btn-primary me-2"
                            onClick={() => Edittiktok(data._id)}
                          >
                            <i className="fa-solid fa-pen-to-square"></i>
                            <i className="fa-brands fa-tiktok"></i>
                          </button>
                          <button
                            onClick={() => handleDelete(data._id)}
                            className="btn btn-danger"
                          >
                            <BsTrash3Fill /> Eliminar
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Torneo;
