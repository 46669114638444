import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { BsArrowLeft, BsCheck } from "react-icons/bs";
import { Oval } from "react-loader-spinner";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const EditTorneo = () => {
  const [formData, setFormData] = useState({
    Titulo: "",
    Slug: "",
    SubTitulo: "",
    Presentacion: "",
    Caso: "",
    Novedades: "",
    Pautas: "",
    Aclaraciones: "",
    Bases: "",
    photo: null,
    photo2: null,
    photo3: null,
    docBases: null,
    docAclaraciones: null,
    docCaso: null,
    docPautas: null,
    FechaInicio: null,
  });
  const [previews, setPreviews] = useState({
    photo: null,
    photo2: null,
    photo3: null,
    docBases: null,
    docAclaraciones: null,
    docCaso: null,
    docPautas: null,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${window.BACKEND_URL}torneo/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${sessionStorage.getItem("token")}`,
            "X-Rol": sessionStorage.getItem("role"),
          },
        });
        const data = response.data;

        setFormData({
          Titulo: data.Titulo,
          Slug: data.Slug,
          SubTitulo: data.SubTitulo,
          Presentacion: data.Presentacion,
          Aclaraciones: data.Aclaraciones,
          Novedades: data.Novedades,
          Pautas: data.Pautas,
          Caso: data.Caso,
          Bases: data.Bases,
          photo: data.photo,
          photo2: data.photo2,
          photo3: data.photo3,
          docBases: data.docBases,
          docAclaraciones: data.docAclaraciones,
          docCaso: data.docCaso,
          docPautas: data.docPautas,
          FechaInicio: data.FechaInicio ? new Date(data.FechaInicio) : null,
        });

        setPreviews({
          photo: data.photo
            ? `${window.BACKEND_URL}uploads/${data.photo}`
            : null,
          photo2: data.photo2
            ? `${window.BACKEND_URL}uploads/${data.photo2}`
            : null,
          photo3: data.photo3
            ? `${window.BACKEND_URL}uploads/${data.photo3}`
            : null,
          docBases: data.docBases
            ? `${window.BACKEND_URL}uploads/${data.docBases}`
            : null,
          docAclaraciones: data.docAclaraciones
            ? `${window.BACKEND_URL}uploads/${data.docAclaraciones}`
            : null,
          docCaso: data.docCaso
            ? `${window.BACKEND_URL}uploads/${data.docCaso}`
            : null,
          docPautas: data.docPautas
            ? `${window.BACKEND_URL}uploads/${data.docPautas}`
            : null,
        });
      } catch (error) {
        console.error("Error fetching the tournament data:", error);
        alert("Error fetching the tournament data");
      }
    };

    fetchData();
  }, [id]);

  const generateSlug = (text) => {
    return text.toLowerCase().replace(/\s+/g, "-");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let slug = value;
    if (name === "Titulo") {
      slug = generateSlug(value);
    }
    setFormData({
      ...formData,
      [name]: value,
      Slug: slug,
    });
  };

  const handleFechaInicioChange = (date) => {
    setFormData({
      ...formData,
      FechaInicio: date,
    });
  };

  const handleFileChange = (e) => {
    const { name } = e.target;
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFormData({
        ...formData,
        [name]: selectedFile,
      });
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviews((prev) => ({
          ...prev,
          [name]: reader.result,
        }));
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    const data = new FormData();
    Object.keys(formData).forEach((key) => {
      data.append(key, formData[key]);
    });

    try {
      const response = await axios.put(
        `${window.BACKEND_URL}torneoedit/${id}`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `${sessionStorage.getItem("token")}`,
            "X-Rol": sessionStorage.getItem("role"),
          },
        }
      );
      if (response.status === 200) {
        alert("Torneo actualizado correctamente");
        navigate("/Torneos");
      }
    } catch (error) {
      console.error("Error al actualizar el torneo:", error);
      alert("Error al actualizar el torneo");
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate("/Torneos");
  };

  const handleEditorChange = (field) => (data) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: data,
    }));
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title mb-0">Editar Torneo</h3>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="Titulo" className="form-label">
                      Título del Torneo:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="Titulo"
                      name="Titulo"
                      value={formData.Titulo}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="SubTitulo" className="form-label">
                      Subtitulo del Torneo:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="SubTitulo"
                      name="SubTitulo"
                      value={formData.SubTitulo}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="FechaInicio" className="form-label">
                      Fecha inicio del torneo:
                    </label>
                    <DatePicker
                      selected={formData.FechaInicio}
                      onChange={handleFechaInicioChange}
                      dateFormat="dd/MM/yyyy"
                      className="form-control"
                      placeholderText="Seleccione fecha de inicio"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="Presentacion" className="form-label">
                      Presentación del Torneo:
                    </label>
                    <ReactQuill
                      value={formData.Presentacion}
                      onChange={handleEditorChange("Presentacion")}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="Bases" className="form-label">
                      Bases del torneo:
                    </label>
                    <ReactQuill
                      value={formData.Bases}
                      onChange={handleEditorChange("Bases")}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="Bases" className="form-label">
                      Caso del torneo:
                    </label>
                    <ReactQuill
                      value={formData.Caso}
                      onChange={handleEditorChange("Caso")}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="Bases" className="form-label">
                      Aclaraciones del torneo:
                    </label>
                    <ReactQuill
                      value={formData.Aclaraciones}
                      onChange={handleEditorChange("Aclaraciones")}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="Bases" className="form-label">
                      Pautas del torneo:
                    </label>
                    <ReactQuill
                      value={formData.Pautas}
                      onChange={handleEditorChange("Pautas")}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="Bases" className="form-label">
                      Novedades del torneo:
                    </label>
                    <ReactQuill
                      value={formData.Novedades}
                      onChange={handleEditorChange("Novedades")}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="photo" className="form-label">
                      Foto del torneo:
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="photo"
                      name="photo"
                      onChange={handleFileChange}
                    />
                    {previews.photo && (
                      <img
                        src={previews.photo}
                        alt="Preview"
                        style={{ maxWidth: "100%", height: "auto" }}
                      />
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="photo2" className="form-label">
                      Foto del baner:
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="photo2"
                      name="photo2"
                      onChange={handleFileChange}
                    />
                    {previews.photo2 && (
                      <img
                        src={previews.photo2}
                        alt="Preview"
                        style={{ maxWidth: "100%", height: "auto" }}
                      />
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="photo3" className="form-label">
                      Foto del cronograma :
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="photo3"
                      name="photo3"
                      onChange={handleFileChange}
                    />
                    {previews.photo3 && (
                      <img
                        src={previews.photo3}
                        alt="Preview"
                        style={{ maxWidth: "100%", height: "auto" }}
                      />
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="docBases" className="form-label">
                      Documento Bases:
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="docBases"
                      name="docBases"
                      onChange={handleFileChange}
                    />
                    {previews.docBases && (
                      <a
                        href={previews.docBases}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Ver Documento Actual
                      </a>
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="docAclaraciones" className="form-label">
                      Documento Aclaraciones:
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="docAclaraciones"
                      name="docAclaraciones"
                      onChange={handleFileChange}
                    />
                    {previews.docAclaraciones && (
                      <a
                        href={previews.docAclaraciones}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Ver Documento Actual
                      </a>
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="docCaso" className="form-label">
                      Documento Caso:
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="docCaso"
                      name="docCaso"
                      onChange={handleFileChange}
                    />
                    {previews.docCaso && (
                      <a
                        href={previews.docCaso}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Ver Documento Actual
                      </a>
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="docPautas" className="form-label">
                      Documento Pautas:
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="docPautas"
                      name="docPautas"
                      onChange={handleFileChange}
                    />
                    {previews.docPautas && (
                      <a
                        href={previews.docPautas}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Ver Documento Actual
                      </a>
                    )}
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                {loading ? (
                  <Oval
                    height={20}
                    width={20}
                    color="#fff"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#fff"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                ) : (
                  <BsCheck />
                )}
                {loading ? "Guardando..." : "Guardar"}
              </button>
              <button
                type="button"
                className="btn btn-secondary ms-2"
                onClick={handleBack}
              >
                <BsArrowLeft /> Volver
              </button>
            </form>
            {error && <p className="text-danger mt-2">{error}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditTorneo;
