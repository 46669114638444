import React, { useState } from "react";
import { BsArrowLeft, BsPlus } from "react-icons/bs";
import axios from "axios";
import ReactQuill from "react-quill";
import { useNavigate, Link } from "react-router-dom";
import { Oval } from "react-loader-spinner"; // Importa una animación de carga de tu elección

import "react-quill/dist/quill.snow.css"; // Estilo por defecto de ReactQuill

const Addnorma = () => {
  const [formData, setFormData] = useState({
    publicado: true,
    titulo: "",
    tags: "",
    resumen: "",
    foto_card: null,
    foto_port: null,
    archivopdf: null,
    body: "",
    slug: "",
  });
  const [loading, setLoading] = useState(false); // Nuevo estado para la animación

  const handleEditorChange = (content) => {
    setFormData({ ...formData, body: content });
  };

  const navigate = useNavigate();
  const handleInputChange = (event) => {
    const { name, value, type, files } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "file" ? files[0] : value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const uploadData = new FormData();

    for (const key in formData) {
      uploadData.append(key, formData[key]);
    }

    try {
      setLoading(true);

      const response = await axios.post(
        `${window.BACKEND_URL}normatividad`,
        uploadData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `${sessionStorage.getItem("token")}`,
            "X-Rol": `${sessionStorage.getItem("role")}`,
          },
        }
      );
      console.log(response.data);
      console.log("Normativa creada exitosamente");
      navigate("/Normativa");
    } catch (error) {
      console.error("Error al crear la normativa:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title mb-0">Agregar Norma</h3>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-2">
                    <input
                      type="checkbox"
                      name="publicado"
                      checked={formData.publicado}
                      onChange={handleInputChange}
                    />
                    <label>Publicado</label>
                  </div>
                  <div className="form-group">
                    <label>Título:</label>
                    <input
                      type="text"
                      name="titulo"
                      className="form-control"
                      value={formData.titulo}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>slug:</label>
                    <input
                      type="text"
                      name="slug"
                      className="form-control"
                      value={formData.slug}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Tags:</label>
                    <input
                      type="text"
                      name="tags"
                      className="form-control"
                      value={formData.tags}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Descripción:</label>
                    <textarea
                      name="resumen"
                      className="form-control"
                      value={formData.resumen}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Portada del card:</label>
                    <input
                      type="file"
                      name="foto_card"
                      className="form-control"
                      accept="image/jpeg, image/png"
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Imagen:</label>
                    <input
                      type="file"
                      className="form-control"
                      name="foto_port"
                      accept="image/jpeg, image/png"
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Archivo PDF:</label>
                    <input
                      type="file"
                      name="archivopdf"
                      className="form-control"
                      accept="application/pdf"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="form-group col-md-12">
                  <label>Cuerpo del documento:</label>
                  <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                    <ReactQuill
                      value={formData.body}
                      onChange={handleEditorChange}
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-between mt-2">
                <Link className="btn btn-primary" to="/Normativa">
                  <BsArrowLeft style={{ fontSize: "1.6em" }} /> Regresar
                </Link>
                <button type="submit" className="btn btn-primary">
                  {loading ? (
                    <Oval
                      color="#00BFFF"
                      height={40}
                      width={40}
                      loading={loading}
                    />
                  ) : (
                    <>
                      Agregar Normativa <BsPlus style={{ fontSize: "2em" }} />
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Addnorma;
