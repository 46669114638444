import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BsArrowLeft, BsPencilSquare } from "react-icons/bs";
import { Oval } from "react-loader-spinner";
import axios from "axios";

const EditarTarea = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [tarea, setTarea] = useState({
    user_id: "",
    descripcion: "",
    fechainicio: "",
    fechafin: "",
    estado: "",
    modified: new Date().toISOString(),
  });
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const obtenerUsuariosYDatosTarea = async () => {
      try {
        // Cargar usuarios
        const responseUsuarios = await axios.get(`${window.BACKEND_URL}users`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            "Content-Type": "application/json",
            "X-Rol": sessionStorage.getItem("role"),
          },
        });
        setUsuarios(responseUsuarios.data);

        // Cargar datos de la tarea
        const responseTarea = await axios.get(
          `${window.BACKEND_URL}tareas/${id}`,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              "Content-Type": "application/json",
              "X-Rol": sessionStorage.getItem("role"),
            },
          }
        );
        setTarea(responseTarea.data);
      } catch (error) {
        console.error("Error al obtener datos:", error);
      }
    };

    obtenerUsuariosYDatosTarea();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !tarea.user_id ||
      !tarea.descripcion ||
      !tarea.fechainicio ||
      !tarea.fechafin ||
      !tarea.estado
    ) {
      console.error("Todos los campos son requeridos");
      return;
    }

    try {
      setLoading(true);

      const response = await axios.put(
        `${window.BACKEND_URL}tareas/${id}`,
        tarea,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            "Content-Type": "application/json",
            "X-Rol": sessionStorage.getItem("role"),
          },
        }
      );

      if (!response.ok) {
        console.error("Error al actualizar la tarea", response.data);
      } else {
        console.log("Tarea actualizada con éxito");
        navigate("/Tareas");
      }
    } catch (error) {
      console.error("Error de conexión:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setTarea({
      ...tarea,
      [e.target.name]: e.target.value,
    });
  };

  const regresarTareas = () => {
    navigate("/Tareas");
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title mb-0">Editar la Tarea existente</h3>
            </div>
            <hr />

            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="usuario" className="form-label">
                    Usuario
                  </label>
                  <select
                    name="user_id"
                    id="usuario"
                    className="form-select"
                    value={tarea.user_id}
                    onChange={handleChange}
                  >
                    <option value="">Seleccione un usuario</option>
                    {usuarios.map((usuario) => (
                      <option key={usuario._id} value={usuario._id}>
                        {usuario.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="mb-3">
                  <label htmlFor="descripcion" className="form-label">
                    Descripción
                  </label>
                  <textarea
                    name="descripcion"
                    id="descripcion"
                    className="form-control"
                    value={tarea.descripcion}
                    onChange={handleChange}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="fechainicio" className="form-label">
                    Fecha Inicio
                  </label>
                  <input
                    type="date"
                    name="fechainicio"
                    id="fechainicio"
                    className="form-control"
                    value={tarea.fechainicio}
                    onChange={handleChange}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="fechafin" className="form-label">
                    Fecha Fin
                  </label>
                  <input
                    type="date"
                    name="fechafin"
                    id="fechafin"
                    className="form-control"
                    value={tarea.fechafin}
                    onChange={handleChange}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="estado" className="form-label">
                    Estado
                  </label>
                  <select
                    name="estado"
                    id="estado"
                    className="form-select"
                    value={tarea.estado}
                    onChange={handleChange}
                  >
                    <option value="">Seleccione un estado</option>
                    <option value="pendiente">Pendiente</option>
                    <option value="completada">Completada</option>
                    <option value="en progreso">En Progreso</option>
                    <option value="cancelada">Cancelada</option>
                  </select>
                </div>

                <div className="d-flex justify-content-between">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={regresarTareas}
                  >
                    <BsArrowLeft style={{ fontSize: "1.6em" }} /> Regresar
                  </button>
                  <button type="submit" className="btn btn-primary">
                    {loading ? (
                      <>
                        <Oval color="#00BFFF" height={40} width={40} />{" "}
                      </>
                    ) : (
                      <>
                        <BsPencilSquare /> Editar Tarea
                      </>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditarTarea;
