import React, { useState, useEffect } from "react";
import { BsArrowLeft, BsPlus } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Oval } from "react-loader-spinner"; // Importa una animación de carga de tu elección

function PostForm() {
  const [formData, setFormData] = useState({
    publicado: true,
    titulo: "",
    tags: "",
    defoto: "",
    categoria: "",
    colaborador_id: "",
    datepost: new Date(),
    sentencia: "",
    expediente: "",
    lugar: "",
    summary: "",
    PDFile: null,
    postcard: null,
    imagen: null,
    body: "",
  });

  const navigate = useNavigate();
  const [colaboradores, setColaboradores] = useState([]);
  const [loading, setLoading] = useState(false); 

  useEffect(() => {
    const fetchColaboradores = async () => {
      try {
        const response = await fetch(`${window.BACKEND_URL}colaboradores`);
        const data = await response.json();
        setColaboradores(data);
      } catch (error) {
        console.error("Error al obtener los colaboradores:", error);
      }
    };

    fetchColaboradores();
  }, []);
  // Manejar cambios en archivos
  const handleFileChange = (event) => {
    const { name, files } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: files[0], // Solo se permite un archivo por campo, puedes ajustar según tus necesidades
    }));
  };

  // Manejar cambios en otros campos
  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleChangeDate = (date) => {
    if (date && !isNaN(date.getTime())) { // Verifica si es una fecha válida
      setFormData({ ...formData, datepost: date });
    }
  };

  // Enviar formulario
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Inicia la animación de carga

    try {
      const data = new FormData();

      // Agregar campos de texto
      Object.entries(formData).forEach(([key, value]) => {
        if (value !== null && typeof value !== "undefined") {
          data.append(key, value);
        }
      });

      console.log(data);

      // Enviar la solicitud
      const response = await fetch(`${window.BACKEND_URL}blogposts`, {
        method: "POST",
        body: data,
      });

      // Manejar la respuesta de la solicitud fetch
      if (response.ok) {
        // La solicitud fue exitosa (código de estado 2xx)
        const responseData = await response.json();
        console.log("Respuesta del servidor:", responseData);
        navigate("/Blogs");
      } else {
        // La solicitud no fue exitosa (código de estado no 2xx)
        console.error("Error en la solicitud:", response.statusText);
      }
    } catch (error) {
      console.error("Error al enviar el formulario:", error);
      // Manejo de errores
    } finally {
      setLoading(false); // Detiene la animación de carga después de la operación
    }
  };

  const Blogs = () => {
    navigate("/Blogs"); // Asegúrate de que la ruta coincida con tu configuración en App.js
  };
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title mb-0">Agregar Post</h3>
            </div>

            <hr />
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group mb-2">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        name="publicado"
                        checked={formData.publicado}
                        onChange={handleInputChange}
                      />
                      <label>Publicado</label>
                    </div>
                    <div className="form-group">
                      <label>Título:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="titulo"
                        value={formData.titulo}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Tags:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="tags"
                        value={formData.tags}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Derecho de la foto:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="defoto"
                        value={formData.defoto}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="form-group">
                      <label>Materia del post:</label>
                      <select
                        className="form-select"
                        name="categoria"
                        value={formData.categoria}
                        onChange={handleInputChange}
                      >
                        <option value="">Selecciona una categoría</option>
                        <option value="Penal">Penal</option>
                        <option value="Civil">Civil</option>
                        <option value="Constitucional">Constitucional</option>
                        <option value="Laboral">Laboral</option>
                        <option value="Procesal">Procesal</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label>Autor del post:</label>
                      <select
                        type="text"
                        className="form-select"
                        name="colaborador_id"
                        value={formData.colaborador_id}
                        onChange={handleInputChange}
                      >
                        <option value="">Selecciona un colaborador</option>
                        {colaboradores.map((colaborador) => (
                          <option key={colaborador._id} value={colaborador._id}>
                            {colaborador.namecol}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group">
                      <label>Fecha del post:</label>
                      <br />
                      <DatePicker
                          dateFormat="dd-MM-yyyy"
                          showIcon
                          selected={formData.datepost}
                          onChange={handleChangeDate}
                          className="form-control"
                          icon="fa fa-calendar"
                      />
                    </div>
                    <div className="form-group">
                      <label>Sentencia:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="sentencia"
                        value={formData.sentencia}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Expediente:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="expediente"
                        value={formData.expediente}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Lugar:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="lugar"
                        value={formData.lugar}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="form-group">
                      <label>Resumen del card:</label>
                      <textarea
                        type="text"
                        className="form-control"
                        name="summary"
                        value={formData.summary}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>URL del archivo PDF:</label>
                      <input
                        className="form-control"
                        type="file"
                        name="PDFile"
                        onChange={handleFileChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Portada del card:</label>
                      <input
                        className="form-control"
                        type="file"
                        name="postcard"
                        onChange={handleFileChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Imagen:</label>
                      <input
                        className="form-control"
                        type="file"
                        name="imagen"
                        onChange={handleFileChange}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Cuerpo del documento:</label>
                    <ReactQuill
                      value={formData.body}
                      onChange={(content) =>
                        handleInputChange({
                          target: { name: "body", value: content },
                        })
                      }
                    />
                  </div>
                </div>
                <br />
                <div className="d-flex justify-content-between">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={Blogs}
                  >
                    <BsArrowLeft style={{ fontSize: "1.6em" }} /> Regresar
                  </button>
                  <button type="submit" className="btn btn-primary">
                    {loading ? (
                      <Oval color="#00BFFF" height={40} width={40} loading={loading} />
                    ) : (
                      <>Agregar Baner <BsPlus style={{ fontSize: "2em" }} /></>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PostForm;
