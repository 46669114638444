import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { BsPencilSquare, BsTrash3Fill } from 'react-icons/bs';
import LoadingOverlay from "../components/LoadingOverlay"; // Importa el nuevo componente
import Moment from 'react-moment';
import $ from 'jquery';

const Facturas = () => {
    const [facturas, setFacturas] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                await getFacturas();
                initializeDataTables();
            } catch (error) {
                console.error("Hubo un error al obtener las facturas", error);
            } finally {
                setLoading(false);
            }
        };

        const initializeDataTables = () => {
            if (!tableRef.current || $.fn.DataTable.isDataTable(tableRef.current)) return;

            $(tableRef.current).DataTable({
                language: {
                    url: '//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json'
                }
            });
        };

        fetchData();
    }, []);

    const tableRef = useRef(null);

    const getFacturas = async () => {
        try {
            const response = await fetch('https://backendhomepage.stemis.com.pe/facturas');
            if (!response.ok) {
                throw new Error('Error al obtener las facturas');
            }
            const data = await response.json();
            setFacturas(data);
        } catch (error) {
            console.error("Hubo un error al obtener las facturas", error);
        }
    };

    const handleDelete = (id) => {
        if (window.confirm("¿Estás seguro de que quieres eliminar esta factura?")) {
            fetch(`http://localhost:3001/facturas/${id}`, { method: 'DELETE' })
                .then(response => response.json())
                .then(() => setFacturas(facturas.filter(factura => factura._id !== id)))
                .catch(error => console.error("Hubo un error al eliminar la factura", error));
        }
    };

    const Editfactura = (facturaid) => {
        navigate(`/Editfactura/${facturaid}`);
    };

    const handleAddFactura = () => {
        navigate('/Addfactura');
    };

    return (
        <div className="position-relative">
            {loading && (
                <LoadingOverlay loading={loading} text="Cargando..." />
            )}
            {!loading && (
            <div className="card">
                <div className="card-header">
                    <span className="float-end">
                        <button className="btn btn-primary" onClick={handleAddFactura}>
                            <i className="fa-solid fa-plus"></i> Agregar Nueva Factura
                        </button>
                    </span>
                    <h3 className="card-title mb-0">Facturas</h3>
                </div>
                <div className="card-body">
                    <table className="table table-bordered" ref={tableRef}>
                        <thead>
                            <tr>
                                <th>Fecha</th>
                                <th>Periodo</th>
                                <th>Serie Número</th>
                                <th>Cliente</th>
                                <th>Descripción</th>
                                <th>Tipo</th>
                                <th>Monto</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {facturas.map(factura => (
                                <tr key={factura._id}>
                                    <td>
                                        <Moment format="DD/MM/YYYY">{factura.fecha}</Moment>
                                    </td>
                                    <td>
                                        <table className="table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <td><strong>Mes:</strong> <Moment format="MM">{factura.fecha}</Moment></td>
                                                    <td><strong>Año:</strong> <Moment format="YYYY">{factura.fecha}</Moment></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    <td>{factura.serienumero}</td>
                                    <td>{factura.cliente}</td>
                                    <td>{factura.descripcion}</td>
                                    <td>{factura.tipo}</td>
                                    <td className="alinear-derecha">{factura.monto.toFixed(2)}</td>
                                    <td>
                                        <button className="btn btn-primary me-2" onClick={() => Editfactura(factura._id)}>
                                            <BsPencilSquare /> Editar
                                        </button>
                                        <button className="btn btn-danger" onClick={() => handleDelete(factura._id)}>
                                            <BsTrash3Fill /> Eliminar
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            )}
        </div>
    );
};

export default Facturas;
