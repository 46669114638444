import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { BsArrowLeft, BsSave } from "react-icons/bs";
import { Oval } from "react-loader-spinner";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const EditTermino = () => {
  const [formData, setFormData] = useState({
    Termino: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(""); // Estado para manejar errores
  const navigate = useNavigate();
  const { id } = useParams(); // Obtener el ID del término desde la URL

  useEffect(() => {
    const fetchTermino = async () => {
      try {
        const response = await axios.get(
          `${window.BACKEND_URL}terminos/condiciones/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `${sessionStorage.getItem("token")}`,
              "X-Rol": sessionStorage.getItem("role"),
            },
          }
        );

        setFormData(response.data);
      } catch (error) {
        console.error("Error al obtener el término:", error);
        setError("Error al obtener el término. Inténtalo de nuevo más tarde.");
      }
    };

    fetchTermino();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.put(
        `${window.BACKEND_URL}terminos/condiciones/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${sessionStorage.getItem("token")}`,
            "X-Rol": sessionStorage.getItem("role"),
          },
        }
      );

      if (response.status === 200) {
        alert("Término actualizado correctamente");
        navigate("/Terminos");
      }
    } catch (error) {
      console.error("Error al actualizar el término:", error);
      setError("Error al actualizar el término. Inténtalo de nuevo más tarde.");
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate("/Terminos"); // Implementar la navegación de regreso a la lista de términos
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title mb-0">Editar Término</h3>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="Termino" className="form-label">
                  Término:
                </label>
                <ReactQuill
                  value={formData.Termino}
                  onChange={(content) =>
                    setFormData({ ...formData, Termino: content })
                  }
                />
              </div>
              {error && <div className="text-danger">{error}</div>}

              <div className="d-flex justify-content-between">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleBack}
                >
                  <BsArrowLeft style={{ fontSize: "1.6em" }} /> Regresar
                </button>
                <button type="submit" className="btn btn-primary">
                  {loading ? (
                    <Oval
                      color="#00BFFF"
                      height={50}
                      width={50}
                      loading={loading}
                    />
                  ) : (
                    <>
                      Guardar Término <BsSave style={{ fontSize: "2em" }} />
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditTermino;
