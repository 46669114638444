import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BsArrowLeft, BsPlus } from "react-icons/bs";
import { Oval } from "react-loader-spinner";

const AddUniversidad = () => {
  const [formData, setFormData] = useState({
    Nombre: "",

    photo: null,
  });
  const [preview1, setPreview1] = useState(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Funciones de manejo de cambio de imagen
  const handlePhotoChange1 = (e) => {
    const selectedPhoto = e.target.files[0];
    setFormData({
      ...formData,
      photo: selectedPhoto,
    });
    if (selectedPhoto) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview1(reader.result);
      };
      reader.readAsDataURL(selectedPhoto);
    } else {
      setPreview1(null);
    }
  };

  // Función de envío del formulario
  const handleSubmit = async (e) => {
    e.preventDefault();

    setError("");
    setLoading(true);
    try {
      const response = await axios.post(
        `${window.BACKEND_URL}universidades`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `${sessionStorage.getItem("token")}`,
            "X-Rol": sessionStorage.getItem("role"),
          },
        }
      );

      if (response.status === 201) {
        setPreview1(null);

        alert("Universidad agregado correctamente");
        navigate("/Universidad");
      }
    } catch (error) {
      console.error("Error al agregar el baner:", error);
      alert("Error al agregar el baner");
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate("/Universidad");
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title mb-0">Agregar una univercidad</h3>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="Nombre" className="form-label">
                      Nombre de la universidad:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="Nombre"
                      name="Nombre"
                      value={formData.Nombre}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="photo" className="form-label">
                      Card:
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="photo"
                      onChange={handlePhotoChange1}
                      required
                      accept="image/*"
                    />
                  </div>
                  {preview1 && (
                    <img
                      src={preview1}
                      alt="Preview"
                      style={{ maxWidth: "100%", height: "auto" }}
                    />
                  )}
                </div>
              </div>
              {error && <div className="text-danger">{error}</div>}

              <div className="d-flex justify-content-between">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleBack}
                >
                  <BsArrowLeft style={{ fontSize: "1.6em" }} /> Regresar
                </button>
                <button type="submit" className="btn btn-primary">
                  {loading ? (
                    <Oval
                      color="#00BFFF"
                      height={50}
                      width={50}
                      loading={loading}
                    />
                  ) : (
                    <>
                      Agregar Baner <BsPlus style={{ fontSize: "2em" }} />
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUniversidad;
