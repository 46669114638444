import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { BsPencilSquare, BsTrash3Fill } from "react-icons/bs";
import axios from "axios";
import LoadingOverlay from "../components/LoadingOverlay";
import Moment from "react-moment";
import $ from "jquery";

const Alumno = () => {
  const [alumnos, setAlumnos] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState("");
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await GetAlumnos();
        //initializeDataTable();
      } catch (error) {
        console.error("Error al obtener los alumnos:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const initializeDataTable = async () => {
      if (!tablaper.current || $.fn.DataTable.isDataTable(tablaper.current))
        return;

      $(tablaper.current).DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
        },
      });
    };

    initializeDataTable();
  }, [loading]);

  const tablaper = useRef(null);
  const GetAlumnos = async () => {
    try {
      const respuesta = await fetch(`${window.BACKEND_URL}alumno`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${sessionStorage.getItem("token")}`,
          "X-Rol": sessionStorage.getItem("role"),
        },
      });

      if (!respuesta.ok) {
        throw new Error("Error al cargar los datos");
      }
      const datos = await respuesta.json();
      setAlumnos(datos);
    } catch (error) {
      console.error("Error al cargar los alumnos:", error);
    }
  };

  const EditAlumno = (alumnoId) => {
    navigate(`/EditAlumno/${alumnoId}`);
  };

  const AddAlumno = () => {
    navigate("/AddAlumno"); // Asegúrate de que la ruta coincida con tu configuración en App.js
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${window.BACKEND_URL}alumno/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${sessionStorage.getItem("token")}`,
          "X-Rol": sessionStorage.getItem("role"),
        },
      });

      const deletedAlumno = alumnos.find((alumno) => alumno._id === id);
      setDeleteMessage(`Alumno "${deletedAlumno.nombre}" eliminado`);
      setShowModal(true);
      setAlumnos(alumnos.filter((alumno) => alumno._id !== id));

      // Cerrar el modal automáticamente después de 2 segundos
      setTimeout(() => {
        setShowModal(false);
      }, 2000);
    } catch (error) {
      console.error("Error al eliminar el alumno", error);
    }
  };

  return (
    <div className="position-relative">
      {loading && <LoadingOverlay loading={loading} text="Cargando..." />}
      {!loading && (
        <div className="card">
          <div class="card-header">
            <span class="float-end">
              <button className="btn btn-primary" onClick={AddAlumno}>
                <i class="fa-solid fa-plus"></i> Agregar Alumno
              </button>
            </span>
            <h5 class="card-title mb-0">Alumnos</h5>
          </div>
          <div className="card-body">
            <table className="table table-bordered" ref={tablaper}>
              <thead>
                <tr>
                  <th>Nombre</th>

                  <th>DNI</th>
                  <th>Email</th>
                  <th>Creado</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {alumnos.map((alumno) => (
                  <tr key={alumno._id}>
                    <td>{alumno.Nombre}</td>

                    <td>{alumno.Dni}</td>
                    <td>{alumno.Correo}</td>
                    <td>
                      <Moment format="DD/MM/YYYY">{alumno.created}</Moment>
                    </td>
                    <td>
                      <button
                        className="btn btn-primary me-2"
                        onClick={() => EditAlumno(alumno._id)}
                      >
                        <BsPencilSquare /> Editar
                      </button>
                      <button
                        className="btn btn-danger"
                        onClick={() => handleDelete(alumno._id)}
                      >
                        <BsTrash3Fill /> Eliminar
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {showModal && (
              <div className="modal" style={{ display: "block" }}>
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Mensaje de Confirmación</h5>
                      <button
                        type="button"
                        className="btn-close"
                        onClick={() => setShowModal(false)}
                      ></button>
                    </div>
                    <div className="modal-body">
                      <p>{deleteMessage}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Alumno;
