import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BsPencilSquare, BsTrash3Fill } from "react-icons/bs";
import axios from "axios";
import LoadingOverlay from "../components/LoadingOverlay"; // Importa el nuevo componente
import $ from "jquery";

const Departamentos = () => {
  const navigate = useNavigate();
  const [universidades, setUniversidad] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getWebinar = async () => {
      try {
        const response = await axios.get(`${window.BACKEND_URL}departamentos`, {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
            "X-Rol": `${sessionStorage.getItem("role")}`,
          },
        });
        setUniversidad(response.data);
      } catch (error) {
        console.error("Error al obtener los Webinarios:", error);
      } finally {
        setLoading(false);
      }
    };
    const fetchData = async () => {
      try {
        setLoading(true);
        await getWebinar();
        //initializeDataTable();
      } catch (error) {
        console.error("Error al obtener los webinar:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const initializeDataTable = async () => {
      if (!tablabaner.current || $.fn.DataTable.isDataTable(tablabaner.current))
        return;

      $(tablabaner.current).DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json",
        },
      });
    };

    initializeDataTable();
  }, [loading]);

  const tablabaner = useRef(null);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${window.BACKEND_URL}departamentos/${id}`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
          "X-Rol": `${sessionStorage.getItem("role")}`,
        },
      });

      // Eliminar el Universidad de la lista actualizada
      setUniversidad((prevBaners) =>
        prevBaners.filter((baner) => baner._id !== id)
      );
    } catch (error) {
      console.error("Error al eliminar el Universidad:", error);
    }
  };

  const Ediywebinar = (uniid) => {
    navigate(`/EditDepartamento/${uniid}`);
  };

  return (
    <div className="position-relative">
      {loading && <LoadingOverlay loading={loading} text="Cargando..." />}
      {!loading && (
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <span className="float-end">
                  <Link to="/AddDepartamento" className="btn btn-primary">
                    <i className="fa-solid fa-plus"></i> Agregar un Departamento
                  </Link>
                </span>
                <h5 className="card-title mb-0">Departamentos</h5>
              </div>
              <hr />

              <div className="card-body">
                <table className="table table-bordered" ref={tablabaner}>
                  <thead>
                    <tr>
                      <th>Nombre</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {universidades.map((data) => (
                      <tr key={data._id}>
                        <td>{data.Nombre}</td>
                        <td>
                          <button
                            className="btn btn-primary me-2"
                            onClick={() => Ediywebinar(data._id)}
                          >
                            <BsPencilSquare /> Editar
                          </button>
                          <button
                            onClick={() => handleDelete(data._id)}
                            className="btn btn-danger"
                          >
                            <BsTrash3Fill /> Eliminar
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Departamentos;
