import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const EditModulo = () => {
  const { id, cursoid } = useParams();
  const navigate = useNavigate();

  const [moduloData, setModulo] = useState({
    titulo: "",
    youtubeLink: "",
    linkType: "YouTube", // Default value
    contenido: "",
    docente: "",
    file1: null,
    file2: null,
  });

  useEffect(() => {
    axios
      .get(`${window.BACKEND_URL}cursos/${id}/sesion/${cursoid}/modulo`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
          "X-Rol": `${sessionStorage.getItem("role")}`,
        },
      })
      .then((response) => {
        setModulo(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [id, cursoid]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setModulo({
      ...moduloData,
      [name]: value,
    });
  };

  const handleFileChange = (e, fileNumber) => {
    const file = e.target.files[0];
    setModulo({
      ...moduloData,
      [`file${fileNumber}`]: file,
    });
  };

  const handleLinkTypeChange = (e) => {
    setModulo({
      ...moduloData,
      tipo: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { titulo, youtubeLink, tipo, contenido, docente, file1, file2 } =
      moduloData;

    const formDataToSend = new FormData();
    formDataToSend.append("titulo", titulo);
    formDataToSend.append("youtubeLink", youtubeLink);
    formDataToSend.append("tipo", tipo); // Add link type to the form data
    formDataToSend.append("contenido", contenido);
    formDataToSend.append("docente", docente);
    if (file1) formDataToSend.append("file1", file1);
    if (file2) formDataToSend.append("file2", file2);

    axios
      .put(
        `${window.BACKEND_URL}cursos/${id}/modulo/${cursoid}`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `${sessionStorage.getItem("token")}`,
            "X-Rol": `${sessionStorage.getItem("role")}`,
          },
        }
      )
      .then((response) => {
        navigate(`/Modulo/${cursoid}`);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleEditorChange = (value) => {
    setModulo((prevData) => ({
      ...prevData,
      contenido: value,
    }));
  };

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h5 className="card-title mb-0">Editar Módulo</h5>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="titulo" className="form-label">
                Título del módulo
              </label>
              <input
                type="text"
                id="titulo"
                name="titulo"
                className="form-control"
                value={moduloData.titulo}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="youtubeLink" className="form-label">
                Enlace de {moduloData.tipo}
              </label>
              <div className="d-flex">
                <input
                  type="text"
                  id="youtubeLink"
                  name="youtubeLink"
                  className="form-control"
                  value={moduloData.youtubeLink}
                  onChange={handleChange}
                />
                <select
                  className="form-select ms-2"
                  value={moduloData.tipo}
                  onChange={handleLinkTypeChange}
                >
                  <option value="YouTube">YouTube</option>
                  <option value="Meet">Meet</option>
                  <option value="Drive">Drive</option>
                </select>
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="editorText" className="form-label">
                Contenido del curso
              </label>
              <ReactQuill
                style={{
                  maxHeight: "200px",
                  overflowY: "auto",
                  padding: "10px",
                  border: "1px solid #ccc",
                }}
                value={moduloData.contenido}
                onChange={handleEditorChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="docente" className="form-label">
                Docente
              </label>
              <input
                type="text"
                id="docente"
                name="docente"
                className="form-control"
                value={moduloData.docente}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="file1" className="form-label">
                Archivo 1
              </label>
              <input
                type="file"
                id="file1"
                className="form-control"
                onChange={(e) => handleFileChange(e, 1)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="file2" className="form-label">
                Archivo 2
              </label>
              <input
                type="file"
                id="file2"
                className="form-control"
                onChange={(e) => handleFileChange(e, 2)}
              />
            </div>
            <button type="submit" className="btn btn-primary">
              Guardar Cambios
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditModulo;
