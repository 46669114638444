import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { BsArrowLeft, BsPencilSquare } from "react-icons/bs";
import { Oval } from "react-loader-spinner";

const Editcol = () => {
  const { id } = useParams();
  const [colaborador, setColaborador] = useState({
    namecol: "",
    photo: null,
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchColaborador = async () => {
      try {
        const response = await axios.get(
          `${window.BACKEND_URL}colaboradores/${id}`,
          {
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
              "Content-Type": "application/json",
              "X-Rol": `${sessionStorage.getItem("role")}`, // Corrección aquí
            },
          }
        );
        setColaborador(response.data);
      } catch (error) {
        console.error("Error al obtener el colaborador:", error);
      }
    };

    fetchColaborador();
  }, [id]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setColaborador({ ...colaborador, photo: file });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setColaborador((prevColaborador) => ({
      ...prevColaborador,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("namecol", colaborador.namecol);
      formData.append("photo", colaborador.photo);

      const response = await axios.put(
        `${window.BACKEND_URL}colaboradores/${id}`,
        formData,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
            "X-Rol": `${sessionStorage.getItem("role")}`,
          },
        }
      );

      navigate("/Colabora");
    } catch (error) {
      console.error("Error al actualizar el colaborador:", error);
    } finally {
      setLoading(false);
    }
  };

  const Colabora = () => {
    navigate("/Colabora");
  };
  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title mb-0">Editar Colaborador</h3>
          </div>
          <hr />
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="namecol" className="form-label">
                  Nombre del Colaborador
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="namecol"
                  name="namecol"
                  value={colaborador.namecol}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="photo" className="form-label">
                  Foto del Colaborador:
                </label>
                {colaborador.photo && (
                  <img
                    src={`${process.env.REACT_APP_API_URL}uploads/${colaborador.photo}`}
                    alt={`Colaborador ${colaborador.photo}`}
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                    }}
                  />
                )}
                <input
                  type="file"
                  className="form-control"
                  id="photo"
                  name="photo"
                  onChange={handleFileChange}
                />
              </div>

              <div className="d-flex justify-content-between">
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={Colabora}
                >
                  <BsArrowLeft style={{ fontSize: "1.6em" }} /> Regresar
                </button>
                <button type="submit" className="btn btn-primary">
                  {loading ? (
                    <>
                      <Oval color="#00BFFF" height={40} width={40} />{" "}
                    </>
                  ) : (
                    <>
                      <BsPencilSquare /> Editar Colaborador
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Editcol;
