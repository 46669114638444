import React, { useState } from "react";
import { BsArrowLeft, BsPlus } from "react-icons/bs";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Oval } from "react-loader-spinner"; // Importa una animación de carga de tu elección

const AddColaborador = () => {
  const [namecol, setNamecol] = useState("");
  const [photo, setPhoto] = useState(null);
  const [type, setType] = useState("Colaborador");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const handleNamecolChange = (event) => {
    setNamecol(event.target.value);
  };

  const handlePhotoChange = (event) => {
    setPhoto(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("namecol", namecol);
    formData.append("photo", photo);

    try {
      setLoading(true);
      const response = await axios.post(
        `${window.BACKEND_URL}colaboradores`,
        formData,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
            "X-Rol": `${sessionStorage.getItem("role")}`,
          },
        }
      );

      navigate("/Colabora");
      // Aquí puedes redirigir a otra página, mostrar un mensaje de éxito, etc.
    } catch (error) {
      console.error("Error al enviar el formulario:", error);
      // Aquí puedes mostrar un mensaje de error al usuario si lo deseas
    } finally {
      setLoading(false);
    }
  };
  const handleTypeChange = (event) => {
    setType(event.target.value);
  };
  const Colabora = () => {
    navigate("/Colabora"); // Asegúrate de que la ruta coincida con tu configuración en App.js
  };
  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title mb-0">Agregar Colaborador</h3>
          </div>
          <hr />
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="namecol" className="form-label">
                  Nombre del Colaborador
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="namecol"
                  value={namecol}
                  onChange={handleNamecolChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="type" className="form-label">
                  Tipo:
                </label>
                <select
                  className="form-select"
                  value={type}
                  onChange={handleTypeChange}
                  id="type"
                >
                  <option value="Colaborador">Colaborador</option>
                  {/* Add other type options if needed */}
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="photo" className="form-label">
                  Foto del Colaborador
                </label>
                <input
                  type="file"
                  className="form-control"
                  id="photo"
                  accept="image/*"
                  onChange={handlePhotoChange}
                  required
                />
              </div>

              <div className="d-flex justify-content-between">
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={Colabora}
                >
                  <BsArrowLeft style={{ fontSize: "1.6em" }} /> Regresar
                </button>
                <button type="submit" className="btn btn-primary">
                  {loading ? (
                    <Oval
                      color="#00BFFF"
                      height={40}
                      width={40}
                      loading={loading}
                    />
                  ) : (
                    <>
                      Agregar Colaborador <BsPlus style={{ fontSize: "2em" }} />
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddColaborador;
