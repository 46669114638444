import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BsArrowLeft, BsPlus } from "react-icons/bs";
import { Oval } from "react-loader-spinner"; // Importa una animación de carga de tu elección
import axios from "axios";
const Addtareas = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // Nuevo estado para la animación
  const [nuevaTarea, setNuevaTarea] = useState({
    alumno_id: "",
    curso_id: "",
    matricula_id: "",
    nota: "",
    path: "",
    created: new Date().toISOString(),
  });

  const [alumnos, setAlumnos] = useState([]);
  const [cursos, setCursos] = useState([]);
  const [matriculas, setMatriculas] = useState([]);

  useEffect(() => {
    const obtenerAlumnos = async () => {
      try {
        const response = await axios.get(`${window.BACKEND_URL}alumno`, {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
            "Content-Type": "application/json",
            "X-Rol": sessionStorage.getItem("role"),
          },
        });

        setAlumnos(response.data);
      } catch (error) {
        console.error("Error al obtener los alumnos:", error);
      }
    };

    const obtenerCursos = async () => {
      try {
        const response = await axios.get(`${window.BACKEND_URL}curso`, {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
            "Content-Type": "application/json",
            "X-Rol": sessionStorage.getItem("role"),
          },
        });

        setCursos(response.data);
      } catch (error) {
        console.error("Error al obtener los cursos:", error);
      }
    };

    const obtenerMatriculas = async () => {
      try {
        const response = await axios.get(`${window.BACKEND_URL}matricula`, {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
            "X-Rol": sessionStorage.getItem("role"),
          },
        });

        setMatriculas(response.data);
      } catch (error) {
        console.error("Error al obtener las matriculas:", error);
      }
    };

    obtenerAlumnos();
    obtenerCursos();
    obtenerMatriculas();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      console.log("Enviando tarea:", nuevaTarea);

      const response = await axios.post(
        `${window.BACKEND_URL}tarea`,
        nuevaTarea,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
            "X-Rol": sessionStorage.getItem("role"),
            "Content-Type": "application/json",
          },
        }
      );

      if (
        !nuevaTarea.alumno_id ||
        !nuevaTarea.curso_id ||
        !nuevaTarea.matricula_id
      ) {
        console.error("Alumno, curso y matricula son requeridos");
        return;
      }
      if (!response.ok) {
        const errorData = await response.json();
        console.error("Error al agregar la tarea", errorData);
      } else {
        // Manejo de respuesta exitosa
        console.log("Tarea agregada con éxito");
        navigate("/Tareas");
      }
    } catch (error) {
      // Manejar errores de conexión
      console.error("Error de conexión:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleChange = (e) => {
    setNuevaTarea({
      ...nuevaTarea,
      [e.target.name]: e.target.value,
    });
  };
  const Tareas = () => {
    navigate("/Tareas");
  };
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title mb-0">Agregar la nueva tarea</h3>
            </div>
            <hr />
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="alumno" className="form-label">
                    Alumno
                  </label>
                  <select
                    name="alumno_id"
                    id="alumno"
                    className="form-select"
                    value={nuevaTarea.alumno_id}
                    onChange={handleChange}
                  >
                    <option value="">Seleccione un alumno:</option>
                    {alumnos.map((alumno) => (
                      <option key={alumno._id} value={alumno._id}>
                        {alumno.nombre}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="mb-3">
                  <label htmlFor="curso" className="form-label">
                    Curso
                  </label>
                  <select
                    name="alumno_id"
                    id="curso"
                    className="form-select"
                    value={nuevaTarea.curso_id}
                    onChange={handleChange}
                  >
                    <option value="">Seleccione un curso:</option>
                    {cursos.map((curso) => (
                      <option key={curso._id} value={curso._id}>
                        {curso.nombre}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="mb-3">
                  <label htmlFor="matricula" className="form-label">
                    Matricula
                  </label>
                  <select
                    name="matricula"
                    id="matricula"
                    className="form-select"
                    value={nuevaTarea.matricula_id}
                    onChange={handleChange}
                  >
                    <option value="">Seleccione una matricula:</option>
                    {matriculas.map((matricula) => (
                      <option key={matricula._id} value={matricula._id}>
                        {matricula._id}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="d-flex justify-content-between">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={Tareas}
                  >
                    <BsArrowLeft style={{ fontSize: "1.6em" }} /> Regresar
                  </button>
                  <button type="submit" className="btn btn-primary">
                    {loading ? (
                      <Oval
                        color="#00BFFF"
                        height={40}
                        width={40}
                        loading={loading}
                      />
                    ) : (
                      <>
                        Agregar Tarea <BsPlus style={{ fontSize: "2em" }} />
                      </>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Addtareas;
