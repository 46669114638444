import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BsArrowLeft, BsPlus } from "react-icons/bs";
import { Oval } from "react-loader-spinner";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Addwebinar = () => {
  const [formData, setFormData] = useState({
    titulo: "",
    slug: "",
    link: "",
    descripcion: "",
    precio: "",
    ponente: "",
    materia: "",
    linkformulario: "",
    estado: false,
    fechaInicio: null,
    fechaFin: null,
    photo: null,
    photo2: null,
    photo3: null,
  });
  const [preview1, setPreview1] = useState(null);
  const [preview2, setPreview2] = useState(null);
  const [preview3, setPreview3] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const generateSlug = (text) => {
    return text.toLowerCase().replace(/\s+/g, "-");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Si el nombre es "titulo", generamos el slug
    const slug = name === "titulo" ? generateSlug(value) : formData.slug;
    setFormData({
      ...formData,
      [name]: value,
      slug: slug, // Actualizamos el estado del slug
    });
  };
  // Funciones de manejo de cambio de fecha
  const handleFechaInicioChange = (date) => {
    setFormData({
      ...formData,
      fechaInicio: date,
    });
  };

  const handleFechaFinChange = (date) => {
    setFormData({
      ...formData,
      fechaFin: date,
    });
  };

  // Funciones de manejo de cambio de imagen
  const handlePhotoChange1 = (e) => {
    const selectedPhoto = e.target.files[0];
    setFormData({
      ...formData,
      photo: selectedPhoto,
    });
    if (selectedPhoto) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview1(reader.result);
      };
      reader.readAsDataURL(selectedPhoto);
    } else {
      setPreview1(null);
    }
  };

  const handlePhotoChange2 = (e) => {
    const selectedPhoto = e.target.files[0];
    setFormData({
      ...formData,
      photo2: selectedPhoto,
    });
    if (selectedPhoto) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview2(reader.result);
      };
      reader.readAsDataURL(selectedPhoto);
    } else {
      setPreview2(null);
    }
  };

  const handlePhotoChange3 = (e) => {
    const selectedPhoto = e.target.files[0];
    setFormData({
      ...formData,
      photo3: selectedPhoto,
    });
    if (selectedPhoto) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview3(reader.result);
      };
      reader.readAsDataURL(selectedPhoto);
    } else {
      setPreview3(null);
    }
  };
  // Función de envío del formulario
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.titulo.trim() === "" || !formData.photo2) {
      setError("Por favor, complete todos los campos obligatorios.");
      return;
    }

    setError("");
    setLoading(true);
    try {
      const response = await axios.post(
        `${window.BACKEND_URL}Webinario`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `${sessionStorage.getItem("token")}`,
            "X-Rol": sessionStorage.getItem("role"),
          },
        }
      );

      if (response.status === 201) {
        setPreview1(null);
        setPreview2(null);
        setPreview3(null);
        alert("Webinario agregado correctamente");
        navigate("/webinar");
      }
    } catch (error) {
      console.error("Error al agregar el baner:", error);
      alert("Error al agregar el baner");
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate("/webinar");
  };
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      [name]: checked,
    });
  };
  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title mb-0">Agregar un Webinar</h3>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="titulo" className="form-label">
                      Estado del webinar:
                    </label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="estado"
                      name="estado"
                      checked={formData.estado || false}
                      onChange={handleCheckboxChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="titulo" className="form-label">
                      Título del webinar:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="titulo"
                      name="titulo"
                      value={formData.titulo}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="materia" className="form-label">
                      Materia del webinar:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="materia"
                      name="materia"
                      value={formData.materia}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="precio" className="form-label">
                      Precio del webinar:
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="precio"
                      name="precio"
                      value={formData.precio}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="link" className="form-label">
                      Link del formulario de inscrpcion:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="linkformulario"
                      name="linkformulario"
                      value={formData.linkformulario}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="link" className="form-label">
                      Link del webinar:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="link"
                      name="link"
                      value={formData.link}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="titulo" className="form-label">
                      Nombre del ponente:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="ponente"
                      name="ponente"
                      value={formData.ponente}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="descripcion" className="form-label">
                      Informacion del ponente:
                    </label>
                    <ReactQuill
                      value={formData.dponente}
                      onChange={(content) =>
                        setFormData({ ...formData, dponente: content })
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="fechaInicio" className="form-label">
                      Fecha de inicio:
                    </label>
                    <DatePicker
                      selected={formData.fechaInicio}
                      onChange={handleFechaInicioChange}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      timeCaption="Hora"
                      dateFormat="dd/MM/yyyy HH:mm"
                      className="form-control"
                      placeholderText="Seleccione fecha y hora de inicio"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="fechaFin" className="form-label">
                      Fecha de fin:
                    </label>
                    <DatePicker
                      selected={formData.fechaFin}
                      onChange={handleFechaFinChange}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      timeCaption="Hora"
                      dateFormat="dd/MM/yyyy HH:mm"
                      className="form-control"
                      placeholderText="Seleccione fecha y hora de fin"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="photo" className="form-label">
                      Card:
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="photo"
                      onChange={handlePhotoChange1}
                      required
                    />
                  </div>
                  {preview1 && (
                    <img
                      src={preview1}
                      alt="Preview"
                      style={{ maxWidth: "100%", height: "auto" }}
                    />
                  )}

                  <div className="mb-3">
                    <label htmlFor="photo2" className="form-label">
                      Baner:
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="photo2"
                      onChange={handlePhotoChange2}
                      required
                    />
                  </div>
                  {preview2 && (
                    <img
                      src={preview2}
                      alt="Preview"
                      style={{ maxWidth: "100%", height: "auto" }}
                    />
                  )}
                  <div className="mb-3">
                    <label htmlFor="photo3" className="form-label">
                      Foto del ponente:
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="photo3"
                      onChange={handlePhotoChange3}
                      required
                    />
                  </div>
                  {preview3 && (
                    <img
                      src={preview3}
                      alt="Preview"
                      style={{ maxWidth: "100%", height: "auto" }}
                    />
                  )}
                </div>
              </div>

              <div className="mb-3">
                <label htmlFor="descripcion" className="form-label">
                  Descripción:
                </label>
                <ReactQuill
                  value={formData.descripcion}
                  onChange={(content) =>
                    setFormData({ ...formData, descripcion: content })
                  }
                />
              </div>
              {error && <div className="text-danger">{error}</div>}

              <div className="d-flex justify-content-between">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleBack}
                >
                  <BsArrowLeft style={{ fontSize: "1.6em" }} /> Regresar
                </button>
                <button type="submit" className="btn btn-primary">
                  {loading ? (
                    <Oval
                      color="#00BFFF"
                      height={50}
                      width={50}
                      loading={loading}
                    />
                  ) : (
                    <>
                      Agregar Baner <BsPlus style={{ fontSize: "2em" }} />
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Addwebinar;
