import React, { useState, useEffect } from "react";
import { BsArrowLeft, BsPlus } from "react-icons/bs";
import axios from "axios";
import { useNavigate, Link, useParams } from "react-router-dom";
import { Oval } from "react-loader-spinner";

const Editnormativacard = () => {
  const [card, setCard] = useState(""); // Cambiado de "portada" a "card"
  const [file, setFile] = useState(null); // Nuevo estado para el archivo
  const { id } = useParams();

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const getCard = async () => {
    // Cambiado de "getPortadas" a "getCard"
    try {
      setLoading(true);
      const response = await axios.get(
        `${window.BACKEND_URL}normatividad/${id}/card`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
            "X-Rol": `${sessionStorage.getItem("role")}`,
          },
        }
      );
      setCard(response.data);
      console.log("TABLA: ", card);
    } catch (error) {
      console.error("Error fetching card:", error);
    } finally {
      setLoading(false);
    }
  };

  console.log("card: ", card);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await getCard(); // Cambiado de "getPortadas" a "getCard"
      } catch (error) {
        console.error("Error al obtener el card:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleInputChange = (e) => {
    setCard(e.target.value);
  };

  const handleSubmit = async (event) => {
    console.log("cardsss: ", card);
    event.preventDefault();
    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("foto_card", file);
      await axios.put(
        `${window.BACKEND_URL}normatividad/${id}/card`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
            "X-Rol": sessionStorage.getItem("role"),
          },
        }
      );
      console.log("card actualizado exitosamente"); // Cambiado de "Portada" a "card"
      navigate("/Normativa");
    } catch (error) {
      console.error("Error al actualizar el card:", error); // Cambiado de "Portada" a "card"
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title mb-0">Editar card</h3>
          </div>
          <div className="card-body">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>card</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{card}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Seleccionar Archivo:</label>
                    <input
                      type="file"
                      name="foto_card"
                      className="form-control"
                      onChange={handleFileChange}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between mt-2">
                <Link className="btn btn-primary" to="/Normativa">
                  <BsArrowLeft style={{ fontSize: "1.6em" }} /> Regresar
                </Link>
                <button type="submit" className="btn btn-primary">
                  {loading ? (
                    <Oval
                      color="#00BFFF"
                      height={40}
                      width={40}
                      loading={loading}
                    />
                  ) : (
                    <>
                      Actualizar card <BsPlus style={{ fontSize: "2em" }} />{" "}
                      {/* Cambiado de "Portada" a "card" */}
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Editnormativacard;
