import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { BsArrowLeft, BsPencilSquare } from "react-icons/bs";
import { Oval } from "react-loader-spinner";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";

const EditCurso = () => {
  const [curso, setCurso] = useState({
    nombre: "",
    descripcion: "",
    detalles: "",
    slug: "",
    precio: {
      alumnos: 0,
      egresadosYBachiller: 0,
      abogadosTitulados: 0,
      agremiadosCAP: 0,
      grupoDe3: 0,
      grupoDe4: 0,
    },
    turno: "",
    modalidad: "",

    fecha_in: null,
    fecha_fin: null,

    dias: "",
    materia: "",
    horas: 0,
    dificultad: 0,
    docente_id: "",
  });
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [docentes, setDocentes] = useState([]);
  const [categorias, setCategorias] = useState([]);

  useEffect(() => {
    const fetchCurso = async () => {
      try {
        const response = await axios.get(`${window.BACKEND_URL}curso/${id}`, {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
            "Content-Type": "application/json",
            "X-Rol": `${sessionStorage.getItem("role")}`,
          },
        });

        // Verifica si la respuesta tiene un estado de éxito (200-299)
        if (response.status >= 200 && response.status < 300) {
          const cursoData = response.data; // Obtén los datos de la respuesta
          setCurso(cursoData);
        } else {
          throw new Error("Error al obtener el curso");
        }
      } catch (error) {
        console.error("Error al obtener el curso:", error);
        // Manejar el error, por ejemplo, mostrar un mensaje al usuario
      }
    };

    const fetchDocente = async () => {
      try {
        const response = await axios.get(
          `${window.BACKEND_URL}users?role=Docente`,
          {
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
              "Content-Type": "application/json",
              "X-Rol": `${sessionStorage.getItem("role")}`,
            },
          }
        );

        const filteredDocentes = response.data.filter(
          (docente) => docente.role === "Docente"
        );
        setDocentes(filteredDocentes);
      } catch (error) {
        console.error("Error fetching docentes:", error);
      }
    };

    const fetchCategorias = async () => {
      try {
        const response = await fetch(`${window.BACKEND_URL}categoria`, {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
            "Content-Type": "application/json",
            "X-Rol": `${sessionStorage.getItem("role")}`,
          },
        });
        if (!response.ok) {
          throw new Error("Error al obtener la categoria");
        }
        const categoriaData = await response.json();
        setCategorias(categoriaData);
      } catch (error) {
        console.error("Error al obtener la categoria:", error);
      }
    };

    fetchCurso();
    fetchDocente();
    fetchCategorias();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name.startsWith("precio.")) {
      // Si el campo es parte del objeto precio, actualiza el estado de precio
      setCurso({
        ...curso,
        precio: {
          ...curso.precio,
          [name.split(".")[1]]: parseFloat(value),
        },
      });
    } else {
      // Si no es parte del objeto precio, actualiza directamente el estado
      setCurso({ ...curso, [name]: value });
    }
  };

  // Agrega la función renderPrecioInputs aquí
  const renderPrecioInputs = (precioSections) => {
    const labels = {
      alumnos: "Alumnos",
      egresadosYBachiller: "Egresados y Bachilleres",
      abogadosTitulados: "Abogados Titulados",
      agremiadosCAP: "Agremiados del Colegio de Abogados de Puno",
      grupoDe3: "Grupos de 3 personas",
      grupoDe4: "Grupos de 4 personas",
    };

    return (
      <div>
        {Object.entries(precioSections).map(([sectionTitle, sectionFields]) => (
          <div key={sectionTitle} className="mb-4">
            <h4>{sectionTitle}</h4>
            {sectionFields.map((campo) => (
              <div key={campo} className="mb-3" style={{ marginLeft: "15px" }}>
                <label htmlFor={campo} className="form-label">
                  {labels[campo]}:
                </label>
                <div>
                  <input
                    type="number"
                    className="form-control"
                    id={campo}
                    name={`precio.${campo}`}
                    value={curso.precio[campo]}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setCurso({ ...curso, pathPortad: file });
  };

  const dificultad = (event) => {
    let value = event.target.value;
    // Ensure the value is a number
    value = isNaN(value) ? 0 : parseInt(value, 10);
    // Limit the value to the range of 1 to 5
    value = Math.min(Math.max(value, 1), 5);
    setCurso({ ...curso, dificultad: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setLoading(true);

      const response = await axios.put(
        `${window.BACKEND_URL}curso/${id}`,
        curso,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
            "Content-Type": "application/json",
            "X-Rol": `${sessionStorage.getItem("role")}`,
          },
        }
      );

      if (response.status !== 200) {
        console.error("Error al actualizar el curso:", response.data.message);
      } else {
        navigate("/Curso"); // Redireccionar después de la actualización exitosa
      }
    } catch (error) {
      console.error("Error al enviar la solicitud:", error);
    } finally {
      setLoading(false);
    }
  };

  const Curso = () => {
    navigate("/Curso"); // Asegúrate de que la ruta coincida con tu configuración en App.js
  };

  const handleEditorChange = (value) => {
    setCurso((prevData) => ({
      ...prevData,
      detalles: value,
    }));
  };
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title mb-0">Editar Curso</h3>
            </div>

            <hr />
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="nombre" className="form-label">
                    Nombre:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="nombre"
                    name="nombre"
                    value={curso.nombre}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="descripcion" className="form-label">
                    Descripcion:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="descripcion"
                    name="descripcion"
                    value={curso.descripcion}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="slug" className="form-label">
                    Slug:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="slug"
                    name="slug"
                    value={curso.slug}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="precio" className="form-label">
                    Precio:
                  </label>
                  {renderPrecioInputs({
                    "Costo del curso": [
                      "alumnos",
                      "egresadosYBachiller",
                      "abogadosTitulados",
                      "agremiadosCAP",
                    ],
                    "Precios corporativos": ["grupoDe3", "grupoDe4"],
                  })}
                </div>
                <div className="mb-3">
                  <label>Autor del post:</label>
                  <select
                    className="form-select"
                    name="docente_id"
                    value={curso.docente_id}
                    onChange={handleInputChange}
                  >
                    <option disabled value="">
                      Selecciona un Docente:
                    </option>
                    {docentes.map((docente) => (
                      <option key={docente._id} value={docente._id}>
                        {docente.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="mb-3">
                  <label htmlFor="modalidad" className="form-label">
                    Modalidad:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="modalidad"
                    name="modalidad"
                    value={curso.modalidad}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="turno" className="form-label">
                    Turno:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="turno"
                    name="turno"
                    value={curso.turno}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="dias" className="form-label">
                    Dias:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="dias"
                    name="dias"
                    value={curso.dias}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="materia" className="form-label">
                    Materia:
                  </label>
                  <select
                    className="form-select"
                    name="materia"
                    value={curso.materia}
                    onChange={handleInputChange}
                  >
                    <option disabled value="">
                      Selecciona una Materia:
                    </option>
                    {categorias.map((categoria) => (
                      <option key={categoria.materia} value={categoria.materia}>
                        {categoria.materia}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="mb-3">
                  <label htmlFor="horas" className="form-label">
                    Horas academicas:
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="horas"
                    name="horas"
                    value={curso.horas}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="dificultad" className="form-label">
                    Dificultad:
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="dificultad"
                    name="dificultad"
                    value={curso.dificultad}
                    onChange={dificultad}
                  />
                </div>
                <div
                  className="mb-3"
                  style={{ maxHeight: "400px", overflowY: "auto" }}
                >
                  <label htmlFor="detalles" className="form-label">
                    Detalles:
                  </label>

                  <ReactQuill
                    id="detalles"
                    name="detalles"
                    value={curso.detalles}
                    onChange={handleEditorChange}
                  />
                </div>
                <div className="d-flex justify-content-between">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={Curso}
                  >
                    <BsArrowLeft style={{ fontSize: "1.6em" }} /> Regresar
                  </button>
                  <button type="submit" className="btn btn-primary">
                    {loading ? (
                      <>
                        <Oval color="#00BFFF" height={40} width={40} />{" "}
                      </>
                    ) : (
                      <>
                        <BsPencilSquare /> Editar Curso
                      </>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditCurso;
