import React, { useState, useEffect } from "react";
import { BsArrowLeft, BsPlus } from "react-icons/bs";
import axios from "axios";
import { useNavigate, Link, useParams } from "react-router-dom";
import { Oval } from "react-loader-spinner";

const EditCursoBrochure = () => {
  const [brochure, setBrochure] = useState("");
  const [file, setFile] = useState(null); // Nuevo estado para el archivo
  const { id } = useParams();

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const getBrochures = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${window.BACKEND_URL}curso/${id}/brochure`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
            "X-Rol": `${sessionStorage.getItem("role")}`,
          },
        }
      );
      setBrochure(response.data);
    } catch (error) {
      console.error("Error fetching brochures:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await getBrochures();
      } catch (error) {
        console.error("Error al obtener la brochure:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]); // Guardar el archivo seleccionado
  };

  const handleInputChange = (e) => {
    setBrochure(e.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("brochure", file);

      const response = await axios.put(
        `${window.BACKEND_URL}curso/${id}/brochure`,
        formData,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
            "X-Rol": `${sessionStorage.getItem("role")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      navigate("/Curso");
    } catch (error) {
      console.error("Error al actualizar la brochure:", error.response);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title mb-0">Editar Brochure</h3>
          </div>
          <div className="card-body">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Brochure</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{brochure}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Seleccionar Archivo:</label>
                    <input
                      type="file"
                      name="brochureFile"
                      className="form-control"
                      onChange={handleFileChange}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between mt-2">
                <Link className="btn btn-primary" to="/Curso">
                  <BsArrowLeft style={{ fontSize: "1.6em" }} /> Regresar
                </Link>
                <button type="submit" className="btn btn-primary">
                  {loading ? (
                    <Oval
                      color="#00BFFF"
                      height={40}
                      width={40}
                      loading={loading}
                    />
                  ) : (
                    <>
                      Actualizar Brochure <BsPlus style={{ fontSize: "2em" }} />
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCursoBrochure;
