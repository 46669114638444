import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const AddModulo = () => {
  const { cursoId, sesionId } = useParams();
  const navigate = useNavigate();

  // State para manejar los campos del formulario
  const [moduloData, setModulo] = useState({
    titulo: "",
    youtubeLink: "",
    linkType: "YouTube", // Default value
    text: "",
    docente: "",
    file1: null,
    file2: null,
  });

  // Función para manejar cambios en los campos de texto
  const handleChange = (e) => {
    const { name, value } = e.target;
    setModulo({
      ...moduloData,
      [name]: value,
    });
  };

  // Función para manejar cambios en los archivos
  const handleFileChange = (e, fileNumber) => {
    const file = e.target.files[0];
    setModulo({
      ...moduloData,
      [`file${fileNumber}`]: file,
    });
  };

  // Función para manejar cambios en el tipo de enlace
  const handleLinkTypeChange = (e) => {
    setModulo({
      ...moduloData,
      linkType: e.target.value,
    });
  };

  // Función para enviar el formulario
  const handleSubmit = (e) => {
    e.preventDefault();
    const { titulo, youtubeLink, linkType, text, docente, file1, file2 } =
      moduloData;

    const formDataToSend = new FormData();
    formDataToSend.append("titulo", titulo);
    formDataToSend.append("type", 0);
    formDataToSend.append("youtubeLink", youtubeLink);
    formDataToSend.append("linkType", linkType); // Add link type to the form data
    formDataToSend.append("contenido", text);
    formDataToSend.append("docente", docente);
    formDataToSend.append("file1", file1);
    formDataToSend.append("file2", file2);

    axios
      .post(
        `${window.BACKEND_URL}cursos/${cursoId}/modulo/${sesionId}`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `${sessionStorage.getItem("token")}`,
            "X-Rol": `${sessionStorage.getItem("role")}`,
          },
        }
      )
      .then((response) => {
        navigate(`/Modulo/${cursoId}`);
      })
      .catch((error) => {
        console.error(error);
      });

    console.log("Form submitted");
  };

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h5 className="card-title mb-0">Agregar Módulo</h5>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="titulo" className="form-label">
                Titulo del modulo
              </label>
              <input
                type="text"
                id="titulo"
                name="titulo"
                className="form-control"
                value={moduloData.titulo}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="docente" className="form-label">
                Docente
              </label>
              <input
                type="text"
                id="docente"
                name="docente"
                className="form-control"
                value={moduloData.docente}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="youtubeLink" className="form-label">
                Enlace de {moduloData.linkType}
              </label>
              <div className="d-flex">
                <input
                  type="text"
                  id="youtubeLink"
                  name="youtubeLink"
                  className="form-control"
                  value={moduloData.youtubeLink}
                  onChange={handleChange}
                />
                <select
                  className="form-select ms-2"
                  value={moduloData.linkType}
                  onChange={handleLinkTypeChange}
                >
                  <option value="YouTube">YouTube</option>
                  <option value="Meet">Meet</option>
                  <option value="Drive">Drive</option>
                </select>
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="editorText" className="form-label">
                Contenido del curso
              </label>
              <ReactQuill
                value={moduloData.text}
                onChange={(value) => setModulo({ ...moduloData, text: value })}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="file1" className="form-label">
                Archivo 1
              </label>
              <input
                type="file"
                id="file1"
                className="form-control"
                onChange={(e) => handleFileChange(e, 1)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="file2" className="form-label">
                Archivo 2
              </label>
              <input
                type="file"
                id="file2"
                className="form-control"
                onChange={(e) => handleFileChange(e, 2)}
              />
            </div>
            <button type="submit" className="btn btn-primary">
              Agregar Módulo
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddModulo;
