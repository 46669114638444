import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { BsArrowLeft, BsPencilSquare } from "react-icons/bs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Oval } from "react-loader-spinner";

const EditCuenta = () => {
  const [cuenta, setCuenta] = useState({ 
    fecha: null, 
    monto: 0, 
    descripcion: '', 
    tipo: '' 
  });
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCuenta = async () => {
      try {
        const response = await fetch(`${window.BACKEND_URL}cuentacorriente/${id}`);
        if (!response.ok) {
          throw new Error('Error al obtener la cuenta corriente');
        }
        const cuentaData = await response.json();
        setCuenta(cuentaData);
      } catch (error) {
        console.error('Error al obtener la cuenta corriente:', error);
        // Manejar el error, por ejemplo, mostrar un mensaje al usuario
      }
    };

    fetchCuenta();

    // Limpiar efecto si es necesario
    return () => {
      // Agregar código de limpieza si es necesario, por ejemplo, cancelar la solicitud
    };
  }, [id]);

  const handleInputChange = (e) => {
    setCuenta({ ...cuenta, [e.target.name]: e.target.value });
  };

  const handleChangeDate = (date) => {
    if (date && !isNaN(date.getTime())) { // Verifica si es una fecha válida
      setCuenta({ ...cuenta, fecha: date });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      const response = await fetch(`http://localhost:3001/cuentacorriente/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(cuenta),
      });

      if (!response.ok) {
        const errorMessage = await response.text();
        console.error('Error al actualizar la cuenta corriente:', errorMessage);
      } else {
        navigate('/Cuenta'); // Redireccionar después de la actualización exitosa
      }
    } catch (error) {
      console.error('Error al enviar la solicitud:', error);
    } finally {
      setLoading(false);
    }
  };
  const Cuenta = () => {
    navigate('/Cuenta'); // Asegúrate de que la ruta coincida con tu configuración en App.js
  };
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">

            <div className="card-header">
              <h3 className="card-title mb-0">Editar Cuenta Corriente</h3>
            </div>

            <hr />
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="fecha" className="form-label">Fecha:</label>
                  <br />
                  <DatePicker
                      dateFormat="dd-MM-yyyy"
                      showIcon
                      selected={new Date(cuenta.fecha)}
                      onChange={handleChangeDate}
                      className="form-control"
                      icon="fa fa-calendar"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="monto" className="form-label">Monto:</label>
                  <input
                    type="number"
                    className="form-control"
                    id="monto"
                    name="monto"
                    value={cuenta.monto}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="descripcion" className="form-label">Descripción:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="descripcion"
                    name="descripcion"
                    value={cuenta.descripcion}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="tipo" className="form-label">Tipo:</label>
                  <select
                    className="form-select"
                    id="tipo"
                    name="tipo"
                    value={cuenta.tipo}
                    onChange={handleInputChange}
                  >
                    <option value="Salida">Salida</option>
                    <option value="Ingreso">Ingreso</option>

                  </select>
                </div>

                <div className="d-flex justify-content-between">
                  <button type="submit" className="btn btn-primary" onClick={Cuenta}>
                    <BsArrowLeft style={{ fontSize: "1.6em" }} /> Regresar
                  </button>
                  <button type="submit" className="btn btn-primary">
                    {loading ? (
                      <>
                        <Oval color="#00BFFF" height={40} width={40} />                      </>
                    ) : (
                      <>
                        <BsPencilSquare /> Editar Cuenta
                      </>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default EditCuenta;
