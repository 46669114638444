import { Link } from "react-router-dom";

const LayoutAdmin = () => {
  return (
    <>
      <div className="sidebar-content js-simplebar">
        <a className="sidebar-brand" href="/blogs">
          <img src="img/logo/logoblanoo.svg" className="w-75" />
        </a>
        <ul className="sidebar-nav">
          <li className="sidebar-item">
            <Link to="/users" className="sidebar-link">
              <i className="fa-solid fa-list"></i>
              <span className="align-middle">Usuarios</span>
            </Link>
          </li>
          <li className="sidebar-item">
            <Link to="/Curso" className="sidebar-link">
              <i className="fa-solid fa-list"></i>
              <span className="align-middle">Cursos</span>
            </Link>
          </li>
          <li className="sidebar-item">
            <Link to="/webinar" className="sidebar-link">
              <i className="fa-solid fa-list"></i>
              <span className="align-middle">Webinars</span>
            </Link>
          </li>
          <li className="sidebar-item">
            <Link to="/inscripcion" className="sidebar-link">
              <i className="fa-solid fa-list"></i>
              <span className="align-middle">Pre-Inscritos</span>
            </Link>
          </li>

          <li className="sidebar-item">
            <Link to="/Normativa" className="sidebar-link">
              <i className="fa-solid fa-list"></i>
              <span className="align-middle">Normatividad Basica</span>
            </Link>
          </li>
          <li className="sidebar-item">
            <Link to="/Torneos" className="sidebar-link">
              <i className="fa-solid fa-list"></i>
              <span className="align-middle">Concursos</span>
            </Link>
          </li>
          <li className="sidebar-item">
            <Link to="/Terminos" className="sidebar-link">
              <i className="fa-solid fa-list"></i>
              <span className="align-middle">Terminos</span>
            </Link>
          </li>
          <li className="sidebar-item">
            <Link to="/blogs" className="sidebar-link">
              <i className="fa-solid fa-list"></i>
              <span className="align-middle">Blogs</span>
            </Link>
          </li>

          <li className="sidebar-item">
            <Link to="/Colabora" className="sidebar-link">
              <i className="fa-solid fa-list"></i>
              <span className="align-middle">Colaboradores</span>
            </Link>
          </li>
          <li className="sidebar-item">
            <Link to="/Message" className="sidebar-link">
              <i className="fa-solid fa-list"></i>
              <span className="align-middle">Mensajes</span>
            </Link>
          </li>
          <li className="sidebar-item">
            <Link to="/Alumno" className="sidebar-link">
              <i className="fa-solid fa-list"></i>
              <span className="align-middle">Alumnos</span>
            </Link>
          </li>
          <li className="sidebar-item">
            <Link to="/Universidad" className="sidebar-link">
              <i className="fa-solid fa-list"></i>
              <span className="align-middle">Universidad</span>
            </Link>
          </li>

          <li className="sidebar-item">
            <Link to="/Departamentos" className="sidebar-link">
              <i className="fa-solid fa-list"></i>
              <span className="align-middle">Departamentos</span>
            </Link>
          </li>

          {/*
            <li className="sidebar-item">
              <Link to="/Tareas" className="sidebar-link">
                <i className="align-middle" data-feather="list"></i>{" "}
                <span className="align-middle">Tareas</span>
              </Link>
            </li>
            */}
          {/*
            <li className="sidebar-item">
              <Link to="/Cuenta" className="sidebar-link">
                <i className="align-middle" data-feather="list"></i>{" "}
                <span className="align-middle">Cuenta Corriente</span>
              </Link>
            </li>
            */}

          <li className="sidebar-item">
            <Link to="/Tareas" className="sidebar-link">
              <i className="fa-solid fa-list"></i>
              <span className="align-middle">Tareas</span>
            </Link>
          </li>
          <li className="sidebar-item">
            <Link to="/Matricula" className="sidebar-link">
              <i className="fa-solid fa-list"></i>
              <span className="align-middle">Matricula</span>
            </Link>
          </li>
          <li className="sidebar-item">
            <Link to="/Baner" className="sidebar-link">
              <i className="fa-solid fa-list"></i>
              <span className="align-middle">Baner</span>
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default LayoutAdmin;
