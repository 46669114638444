import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BsArrowLeft, BsPlus } from "react-icons/bs";
import { Oval } from "react-loader-spinner";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AddTorneo = () => {
  const [formData, setFormData] = useState({
    Titulo: "",
    Slug: "",
    SubTitulo: "",
    Presentacion: "",
    Bases: "",
    Caso: "",
    Aclaraciones: "",
    Pautas: "",
    Novedades: "",
    photo: null,
    photo2: null,
    photo3: null,
    FechaInicio: null,
    docBases: null,
    docAclaraciones: null,
    docCaso: null,
    docPautas: null,
    Tiktok: null,
  });

  const [previewPhoto, setPreviewPhoto] = useState(null);
  const [previewPhoto2, setPreviewPhoto2] = useState(null);
  const [previewPhoto3, setPreviewPhoto3] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const generateSlug = (text) => {
    return text.toLowerCase().replace(/\s+/g, "-");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let slug = value;
    if (name === "Titulo") {
      slug = generateSlug(value);
    }
    setFormData({
      ...formData,
      [name]: value,
      Slug: slug,
    });
  };

  const handleFechaInicioChange = (date) => {
    setFormData({
      ...formData,
      FechaInicio: date,
    });
  };

  const handlePhotoChange = (e, photoField, setPreviewPhotoField) => {
    const selectedPhoto = e.target.files[0];
    if (selectedPhoto) {
      setFormData({
        ...formData,
        [photoField]: selectedPhoto,
      });
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewPhotoField(reader.result);
      };
      reader.readAsDataURL(selectedPhoto);
    }
  };

  const handleFileChange = (e, fileField) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFormData({
        ...formData,
        [fileField]: selectedFile,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    const data = new FormData();
    console.log(data);
    Object.keys(formData).forEach((key) => {
      data.append(key, formData[key]);
    });

    try {
      const response = await axios.post(`${window.BACKEND_URL}torneo`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `${sessionStorage.getItem("token")}`,
          "X-Rol": sessionStorage.getItem("role"),
        },
      });
      if (response.status === 200) {
        setPreviewPhoto(null);
        setPreviewPhoto2(null);
        setPreviewPhoto3(null);
        alert("Torneo agregado correctamente");
        navigate("/Torneos");
      }
    } catch (error) {
      console.error("Error al agregar el torneo:", error);
      alert("Error al agregar el torneo");
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate("/Torneos");
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title mb-0">Agregar un Torneo</h3>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="Titulo" className="form-label">
                      Título del Torneo:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="Titulo"
                      name="Titulo"
                      value={formData.Titulo}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="SubTitulo" className="form-label">
                      Subtitulo del Torneo:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="SubTitulo"
                      name="SubTitulo"
                      value={formData.SubTitulo}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="FechaInicio" className="form-label">
                      Fecha inicio del torneo:
                    </label>
                    <DatePicker
                      selected={formData.FechaInicio}
                      onChange={handleFechaInicioChange}
                      showTimeSelect={false}
                      dateFormat="dd/MM/yyyy"
                      className="form-control"
                      placeholderText="Seleccione fecha de inicio"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="Presentacion" className="form-label">
                      Presentacion del Torneo:
                    </label>
                    <ReactQuill
                      value={formData.Presentacion}
                      onChange={(content) =>
                        setFormData({ ...formData, Presentacion: content })
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="Bases" className="form-label">
                      Bases del torneo:
                    </label>
                    <ReactQuill
                      value={formData.Bases}
                      onChange={(content) =>
                        setFormData({ ...formData, Bases: content })
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="Bases" className="form-label">
                      Caso del torneo:
                    </label>
                    <ReactQuill
                      value={formData.Caso}
                      onChange={(content) =>
                        setFormData({ ...formData, Caso: content })
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="Bases" className="form-label">
                      Aclaraciones del torneo:
                    </label>
                    <ReactQuill
                      value={formData.Aclaraciones}
                      onChange={(content) =>
                        setFormData({ ...formData, Aclaraciones: content })
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="Bases" className="form-label">
                      Pautas del torneo:
                    </label>
                    <ReactQuill
                      value={formData.Pautas}
                      onChange={(content) =>
                        setFormData({ ...formData, Pautas: content })
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="Bases" className="form-label">
                      Novedades del torneo:
                    </label>
                    <ReactQuill
                      value={formData.Novedades}
                      onChange={(content) =>
                        setFormData({ ...formData, Novedades: content })
                      }
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="photo" className="form-label">
                      Foto del torneo:
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="photo"
                      name="photo"
                      onChange={(e) =>
                        handlePhotoChange(e, "photo", setPreviewPhoto)
                      }
                      required
                      accept="image/*"
                    />
                  </div>
                  {previewPhoto && (
                    <img
                      src={previewPhoto}
                      alt="Preview"
                      style={{ maxWidth: "100%", height: "auto" }}
                    />
                  )}
                  <div className="mb-3">
                    <label htmlFor="photo2" className="form-label">
                      Baner del torneo:
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="photo2"
                      name="photo2"
                      onChange={(e) =>
                        handlePhotoChange(e, "photo2", setPreviewPhoto2)
                      }
                      required
                      accept="image/*"
                    />
                  </div>
                  {previewPhoto2 && (
                    <img
                      src={previewPhoto2}
                      alt="Preview"
                      style={{ maxWidth: "75%", height: "auto" }}
                    />
                  )}
                  <div className="mb-3">
                    <label htmlFor="photo3" className="form-label">
                      Cronograma del torneo:
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="photo3"
                      name="photo3"
                      onChange={(e) =>
                        handlePhotoChange(e, "photo3", setPreviewPhoto3)
                      }
                      required
                      accept="image/*"
                    />
                  </div>
                  {previewPhoto3 && (
                    <img
                      src={previewPhoto3}
                      alt="Preview"
                      style={{ maxWidth: "75%", height: "auto" }}
                    />
                  )}
                  <div className="mb-3">
                    <label htmlFor="docBases" className="form-label">
                      Documentos de Bases del torneo:
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="docBases"
                      name="docBases"
                      onChange={(e) => handleFileChange(e, "docBases")}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="docAclaraciones" className="form-label">
                      Documentos de Aclaraciones del torneo:
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="docAclaraciones"
                      name="docAclaraciones"
                      onChange={(e) => handleFileChange(e, "docAclaraciones")}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="docCaso" className="form-label">
                      Documentos de caso del torneo:
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="docCaso"
                      name="docCaso"
                      onChange={(e) => handleFileChange(e, "docCaso")}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="docPautas" className="form-label">
                      Documentos de pautas del torneo:
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="docPautas"
                      name="docPautas"
                      onChange={(e) => handleFileChange(e, "docPautas")}
                    />
                  </div>
                </div>
              </div>

              {error && <div className="text-danger">{error}</div>}

              <div className="d-flex justify-content-between">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleBack}
                >
                  <BsArrowLeft style={{ fontSize: "1.6em" }} /> Regresar
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loading}
                >
                  {loading ? (
                    <Oval color="#00BFFF" height={20} width={20} />
                  ) : (
                    <>
                      Agregar Torneo <BsPlus style={{ fontSize: "1.5em" }} />
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTorneo;
