import React, { useState } from 'react';
import { BsArrowLeft, BsPlus } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Oval } from "react-loader-spinner"; // Importa una animación de carga de tu elección

const Addfactura = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false); // Nuevo estado para la animación

    const [newFactura, setFactura] = useState({
        fecha: new Date(),
        serienumero: '',
        cliente: '',
        descripcion: '',
        monto: 0,
        tipo: '',
        created: new Date().toISOString()
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        const isNumberField = name === 'monto';
        const valueToSet = isNumberField ? Number(value) : value;

        setFactura({ ...newFactura, [name]: valueToSet });
    };

    const handleChangeDate = (date) => {
        if (date && !isNaN(date.getTime())) { // Verifica si es una fecha válida
          setFactura({ ...newFactura, fecha: date });
        }
      };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);

            const response = await fetch('https://backendhomepage.stemis.com.pe/facturas', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newFactura),
            });


            if (!response.ok) {

                const errorData = await response.json();
                const error = new Error('Error en la respuesta del servidor');
                error.data = errorData;
                throw error;
            }
            const result = await response.json();
            console.log('Factura creada con éxito:', result);
            navigate('/Facturas');
        } catch (error) {
            console.error("Hubo un error al crear la factura:", error.message);
            if (error.data) {
                console.error("Detalles del error:", error.data);
            }
            alert("Error al crear la factura: " + error.message);
        } finally {
            setLoading(false);
        }
    };
    const Factura = () => {
        navigate('/Facturas'); // Asegúrate de que la ruta coincida con tu configuración en App.js
    };
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title mb-0">Agregar Factura</h3>
                        </div>
                        <div className="card-body" >
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="fecha" className="form-label">Fecha</label>
                                    <br />
                                    <DatePicker
                                        dateFormat="dd-MM-yyyy"
                                        showIcon
                                        selected={newFactura.fecha}
                                        onChange={handleChangeDate}
                                        className="form-control"
                                        icon="fa fa-calendar"
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="serienumero" className="form-label">Serie Número</label>
                                    <input type="text" className="form-control" name="serienumero" value={newFactura.serienumero} onChange={handleChange} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="cliente" className="form-label">Cliente</label>
                                    <input type="text" className="form-control" name="cliente" value={newFactura.cliente} onChange={handleChange} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="descripcion" className="form-label">Descripción</label>
                                    <textarea className="form-control" name="descripcion" value={newFactura.descripcion} onChange={handleChange}></textarea>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="ingreso" className="form-label">Monto</label>
                                    <input type="number" className="form-control" name="monto" value={newFactura.monto} onChange={handleChange} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="tipoTransaccion" className="form-label">Tipo</label>
                                    <select className="form-select" name="tipo" value={newFactura.tipo} onChange={handleChange}>
                                        <option value="">Seleccione el tipo de factura:</option>
                                        <option value="Compra">Compra</option>
                                        <option value="Venta">Venta</option>
                                        <option value="Gastos">Gastos</option>
                                    </select>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <button type="submit" className="btn btn-primary" onClick={Factura}>
                                        <BsArrowLeft style={{ fontSize: "1.6em" }} /> Regresar
                                    </button>
                                    <button type="submit" className="btn btn-primary">
                                        {loading ? (
                                        <Oval color="#00BFFF" height={40} width={40} loading={loading} />
                                        ) : (
                                        <>Agregar Factura <BsPlus style={{ fontSize: "2em" }} /></>
                                        )}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div >

        </>
    );
};

export default Addfactura;
