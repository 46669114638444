import React, { useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const EditTiktok = () => {
  const { id } = useParams(); // Este es probablemente el ID del torneo o un identificador similar
  const [tiktokId, setTiktokId] = useState(""); // Declarar estado para el ID de TikTok

  const handleSubmit = (e) => {
    e.preventDefault();

    const url = `${window.BACKEND_URL}torneo/tiktok/${id}`;

    // Datos a enviar en el cuerpo de la solicitud
    const data = {
      tiktokId: tiktokId, // Enviar el ID de TikTok en el cuerpo de la solicitud
    };

    // Obtener el token del almacenamiento local o de donde lo tengas almacenado

    axios
      .put(url, data, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
          "X-Rol": sessionStorage.getItem("role"),
          // Incluye otros headers aquí si es necesario
        },
      })
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        // Manejar error
        console.error("Error al actualizar TikTok:", error);
        // Opcionalmente, mostrar un mensaje de error al usuario
      });
  };
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title mb-0">Modificar el TikTok</h3>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="container">
                  <div className="label fw-bolder">
                    ID del TikTok (recuerda que solo el ID)
                  </div>
                  <div className="label">
                    https://tiktok.com/@ejemplo/video/ (Solo selecciona este ID:{" "}
                    <i>7386017231840218373</i>)
                  </div>
                  <input
                    type="number"
                    className="form-control w-50"
                    value={tiktokId}
                    onChange={(e) => setTiktokId(e.target.value)}
                    required
                  />
                  <button type="submit" className="btn btn-primary mt-3">
                    Enviar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* Modal para editar novedad */}
      </div>
    </>
  );
};

export default EditTiktok;
