import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { BsArrowLeft, BsPencilSquare } from "react-icons/bs";
import { Oval } from "react-loader-spinner";

const Editusers = () => {
  const [user, setUser] = useState({
    name: "",
    email: "",
    role: "",
    estado: true,
    photo: null,
  });
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch(`${window.BACKEND_URL}users/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${sessionStorage.getItem("token")}`,
            "X-Rol": sessionStorage.getItem("role"),
          },
        });

        if (!response.ok) {
          throw new Error("Error al obtener el usuario");
        }
        const userData = await response.json();
        setUser(userData);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchUser();
  }, [id]);

  const handleInputChange = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value; // Manejar el cambio de valor del checkbox
    setUser({ ...user, [e.target.name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setUser({ ...user, photo: file });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      const response = await fetch(`${window.BACKEND_URL}users/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${sessionStorage.getItem("token")}`,
          "X-Rol": sessionStorage.getItem("role"),
        },
        body: JSON.stringify(user),
      });

      if (response.ok) {
        navigate("/users");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title mb-0">Editar Usuario</h3>
          </div>
          <hr />
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="mb-3 form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="estado"
                  name="estado"
                  checked={user.estado}
                  onChange={handleInputChange}
                />
                <label className="form-check-label" htmlFor="estado">
                  Habilitado
                </label>
              </div>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Foto:
                </label>
                <img
                  src={`${window.BACKEND_URL}uploads/${user.photo}`}
                  alt={`Foto de ${user.name}`}
                  style={{ width: "50px", height: "50px", borderRadius: "50%" }}
                />
                <input
                  type="file"
                  className="form-control"
                  id="photo"
                  name="photo"
                  onChange={handleFileChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Nombre:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  value={user.name}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email:
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  value={user.email}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="role" className="form-label">
                  Rol:
                </label>
                <select
                  className="form-select"
                  id="role"
                  name="role"
                  value={user.role}
                  onChange={handleInputChange}
                >
                  <option disabled value="">
                    Selecciona un rol
                  </option>
                  <option value="Docente">Docente</option>
                  <option value="Admin">Admin</option>
                </select>
              </div>
              <div className="d-flex justify-content-between">
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={() => navigate("/users")}
                >
                  <BsArrowLeft style={{ fontSize: "1.6em" }} /> Regresar
                </button>
                <button type="submit" className="btn btn-primary">
                  {loading ? (
                    <>
                      <Oval color="#00BFFF" height={40} width={40} />{" "}
                    </>
                  ) : (
                    <>
                      <BsPencilSquare /> Actualizar Usuario
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Editusers;
