import React, { useState } from 'react';
import axios from 'axios'; // O puedes usar fetch
import { BsArrowLeft, BsPlus } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Oval } from 'react-loader-spinner';

const AddCuenta = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [cuenta, setCuenta] = useState({
    fecha: new Date(),
    monto: 0,
    descripcion: '',
    tipo: '',

  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCuenta({ ...cuenta, [name]: value });
  };

  const handleChangeDate = (date) => {
    if (date && !isNaN(date.getTime())) { // Verifica si es una fecha válida
      setCuenta({ ...cuenta, fecha: date });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      await axios.post('https://backendhomepage.stemis.com.pe/cuentacorriente', cuenta);
      // Manejo después del envío exitoso (redireccionar, mostrar mensaje, etc.)
      navigate('/Cuenta');
    } catch (error) {
      console.error('Error al agregar la cuenta corriente', error.response.data);
      // Manejo de errores (mostrar mensaje al usuario, etc.)
    } finally {
      setLoading(false);
    }
  };

  const Cuenta = () => {
    navigate('/Cuenta'); // Asegúrate de que la ruta coincida con tu configuración en App.js
  };
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title mb-0">Agregar Cuenta Corriente</h3>
            </div>
            <hr />
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="fecha" className="form-label">Fecha:</label>
                  <br />
                  <DatePicker
                      dateFormat="dd-MM-yyyy"
                      showIcon
                      selected={cuenta.fecha}
                      onChange={handleChangeDate}
                      className="form-control"
                      icon="fa fa-calendar"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="monto" className="form-label">Monto:</label>
                  <input
                    type="number"
                    className="form-control"
                    id="monto"
                    name="monto"
                    value={cuenta.monto}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="descripcion" className="form-label">Descripción:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="descripcion"
                    name="descripcion"
                    value={cuenta.descripcion}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="tipo" className="form-label">Tipo:</label>
                  <select
                    className="form-select"
                    id="tipo"
                    name="tipo"
                    value={cuenta.tipo}
                    onChange={handleInputChange}
                  >
                    <option value="">Ingrese un tipo de Cuenta</option>
                    <option value="Ingreso">Ingreso</option>

                    <option value="Salida">Salida</option>

                  </select>
                </div>

                <div className="d-flex justify-content-between">
                  <button type="submit" className="btn btn-primary" onClick={Cuenta}>
                    <BsArrowLeft style={{ fontSize: "1.6em" }} /> Regresar
                  </button>
                  <button type="submit" className="btn btn-primary">
                    {loading ? (
                      <>
                        <Oval color="#00BFFF" height={40} width={40} />                      </>
                    ) : (
                      <>
                        <BsPlus /> Agregar Cuenta Corriente
                      </>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCuenta;
