import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Modal from "react-modal";
import axios from "axios";
import { Oval } from "react-loader-spinner";

const ModuleConfig = () => {
  const { id } = useParams();
  const [selectedType, setSelectedType] = useState("");
  const [content, setContent] = useState("");
  const [files, setFiles] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modulo, setModulo] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchModulo = async () => {
    try {
      const response = await axios.get(
        `${window.BACKEND_URL}modulo/sesion/${id}/buscar`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
            "X-Rol": `${sessionStorage.getItem("role")}`,
          },
        }
      );
      setModulo(response.data.adicional || []);
    } catch (error) {
      console.error("Error fetching module:", error);
    }
  };

  useEffect(() => {
    fetchModulo();
  }, [id]);

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setFiles([...files, ...newFiles]);
  };

  const handleEdit = (item) => {
    setSelectedItem(item);
    setSelectedType(item.tipo.toString()); // Asegúrate de convertir el tipo a string
    setContent(item.content || "");
    setEditModalOpen(true);
  };

  const handleDelete = async (itemId) => {
    try {
      await axios.delete(
        `${window.BACKEND_URL}modulo/sesion/${id}/adicional/${itemId}`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
            "X-Rol": `${sessionStorage.getItem("role")}`,
          },
        }
      );
      fetchModulo();
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  const handleSaveModal = async () => {
    if (selectedItem) {
      try {
        await axios.put(
          `${window.BACKEND_URL}modulo/sesion/${id}/adicional/${selectedItem.AdicionalId}`,
          {
            content: content,
            type: selectedType,
          },
          {
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
              "X-Rol": `${sessionStorage.getItem("role")}`,
            },
          }
        );
        setEditModalOpen(false);
        fetchModulo(); // Refresca los datos después de la actualización
      } catch (error) {
        console.error("Error updating item:", error);
      }
    }
  };

  const handleCancelModal = () => {
    setEditModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("type", selectedType);
    formData.append("content", content);

    if (selectedType === "1") {
      formData.append("file1", files[0]);
      formData.append("file2", files[1]);
    } else if (selectedType === "2") {
      formData.append("file3", files[0]);
    }

    try {
      await axios.put(
        `${window.BACKEND_URL}modulo/sesion/${id}/agregar`,
        formData,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
            "X-Rol": `${sessionStorage.getItem("role")}`,
          },
        }
      );
      setContent("");
      setFiles([]);
      setSelectedType("");
      fetchModulo();
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false);
    }
  };

  const getTypeDescription = (type) => {
    switch (type) {
      case 1:
        return "Material adicional";
      case 2:
        return "Tareas";
      case 3:
        return "Foros";
      default:
        return "Desconocido";
    }
  };

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h5 className="card-title mb-0">Agregar contenido al curso</h5>
        </div>
        <div className="card-body">
          <p>
            En esta parte vas a poder agregar contenido al curso como material
            adicional, foro y tareas, donde cada campo es requerido.
          </p>
          <form onSubmit={handleSubmit}>
            <select
              className="form-select mb-3"
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value)}
            >
              <option value="">Selecciona un tipo</option>
              <option value="1">Materia adicional</option>
              <option value="2">Tareas</option>
              <option value="3">Foros</option>
            </select>

            {selectedType === "1" && (
              <>
                <ReactQuill
                  value={content}
                  onChange={setContent}
                  placeholder="Escribe aquí el contenido..."
                />
                <div className="mt-3">
                  <label>Sube los archivos:</label>
                  <input
                    className="form-control"
                    type="file"
                    name="files"
                    multiple
                    onChange={handleFileChange}
                  />
                </div>
                <div className="mt-3">
                  <label>Sube los archivos:</label>
                  <input
                    className="form-control"
                    type="file"
                    name="files"
                    multiple
                    onChange={handleFileChange}
                  />
                </div>
              </>
            )}

            {selectedType === "2" && (
              <>
                <ReactQuill
                  value={content}
                  onChange={setContent}
                  placeholder="Escribe aquí el contenido..."
                />
                <div className="mt-3">
                  <label>Sube el archivo:</label>
                  <input
                    className="form-control"
                    type="file"
                    name="files"
                    onChange={handleFileChange}
                  />
                </div>
              </>
            )}

            {selectedType === "3" && (
              <>
                <ReactQuill
                  value={content}
                  onChange={setContent}
                  placeholder="Escribe aquí el contenido..."
                />
              </>
            )}

            <div className="mt-3">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                {loading ? (
                  <Oval color="#fff" height={20} width={20} />
                ) : (
                  "Agregar contenido"
                )}
              </button>
            </div>
          </form>
        </div>
        <div className="card-footer">
          {modulo.length === 0 ? (
            <p>Aún no hay contenido en el curso.</p>
          ) : (
            <table className="table">
              <thead>
                <tr>
                  <th>Tipo</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {modulo.map((item, index) => (
                  <tr key={index}>
                    <td>{getTypeDescription(item.tipo)}</td>
                    <td>
                      <button
                        className="btn btn-primary me-2"
                        onClick={() => handleEdit(item)}
                      >
                        Editar
                      </button>
                      <button
                        className="btn btn-danger"
                        onClick={() => handleDelete(item.AdicionalId)}
                      >
                        Eliminar
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>

      <Modal
        isOpen={editModalOpen}
        onRequestClose={handleCancelModal}
        contentLabel="Editar contenido"
      >
        <div className="modal-header">
          <h5 className="modal-title">Editar contenido</h5>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={handleCancelModal}
          ></button>
        </div>
        <div className="modal-body">
          <ReactQuill
            value={content}
            onChange={setContent}
            placeholder="Escribe aquí el contenido..."
          />
        </div>
        <div className="modal-footer">
          <button className="btn btn-primary" onClick={handleSaveModal}>
            Guardar
          </button>
          <button className="btn btn-secondary" onClick={handleCancelModal}>
            Cancelar
          </button>
        </div>
      </Modal>
    </>
  );
};

export default ModuleConfig;
