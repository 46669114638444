import React, { useState, useEffect, useRef } from "react";
import { BsArrowLeft, BsPlus } from "react-icons/bs";
import axios from "axios";

import { useNavigate, Link, useParams } from "react-router-dom";
import { Oval } from "react-loader-spinner"; // Importa una animación de carga de tu elección
import "react-quill/dist/quill.snow.css"; // Estilo por defecto de ReactQuill

const Adddocumento = () => {
  const [formData, setFormData] = useState({
    documentos: null,
  });
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [documentos, setDocumentos] = useState([]);

  const navigate = useNavigate();

  const getDocumentos = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${window.BACKEND_URL}curso/${id}/documentos`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
            "Content-Type": "application/json",
            "X-Rol": `${sessionStorage.getItem("role")}`,
          },
        }
      );
      setDocumentos(response.data);
    } catch (error) {
      console.error("Error fetching documentos:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await getDocumentos();
        //initializeDataTable();
      } catch (error) {
        console.error("Error al obtener los documentos:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (event) => {
    const { name, value, type, files } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "file" ? files[0] : value,
    }));
  };

  const handleDelete = async (idDocumento) => {
    try {
      await axios.delete(
        `${window.BACKEND_URL}curso/${id}/documentos/${idDocumento}`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
            "X-Rol": `${sessionStorage.getItem("role")}`,
          },
        }
      );
      await getDocumentos();
    } catch (error) {
      console.error("Error al eliminar el documento:", error);
    }
  };

  const tablanorma = useRef(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const uploadData = new FormData();

    for (const key in formData) {
      uploadData.append(key, formData[key]);
    }

    try {
      setLoading(true);

      const response = await axios.put(
        `${window.BACKEND_URL}curso/${id}/documento`,
        uploadData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `${sessionStorage.getItem("token")}`,
            "X-Rol": `${sessionStorage.getItem("role")}`,
          },
        }
      );
      navigate("/Adddocumento");
      navigate(`/Adddocumento/${id}`);
    } catch (error) {
      console.error("Error al agregar el documento:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title mb-0">Agregar Documentos</h3>
          </div>
          <div className="card-body">
            <table className="table table-bordered" ref={tablanorma}>
              <thead>
                <tr>
                  <th>Documentos</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {documentos.map((documento) => (
                  <tr key={documento.filename}>
                    <td>{documento.filename}</td>
                    <td>
                      <button
                        className="btn btn-danger"
                        onClick={() => handleDelete(documento.idDocumento)}
                      >
                        Eliminar
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Archivo PDF:</label>
                    <input
                      type="file"
                      name="documentos"
                      className="form-control"
                      accept="application/pdf"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-between mt-2">
                <Link className="btn btn-primary" to="/Curso">
                  <BsArrowLeft style={{ fontSize: "1.6em" }} /> Regresar
                </Link>
                <button type="submit" className="btn btn-primary">
                  {loading ? (
                    <Oval
                      color="#00BFFF"
                      height={40}
                      width={40}
                      loading={loading}
                    />
                  ) : (
                    <>
                      Agregar Documento <BsPlus style={{ fontSize: "2em" }} />
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Adddocumento;
