import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import axios from "axios";
const AllMessage = () => {
  const [message, setMessage] = useState({
    nombre: "",
    email: "",
    asunto: "",
    telefono: "",
    message: "",
  });
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMessage = async () => {
      try {
        const response = await axios.get(
          `${window.BACKEND_URL}messages/${id}`,
          {
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
              "X-Rol": `${sessionStorage.getItem("role")}`,
            },
          }
        );

        if (!response.data) {
          throw new Error("Error al obtener el mensaje");
        }

        setMessage(response.data);
      } catch (error) {
        console.error("Error al obtener el mensaje:", error);
      }
    };

    fetchMessage();
    return () => {};
  }, [id]);

  const Message = () => {
    navigate("/Message"); // Asegúrate de que la ruta coincida con tu configuración en App.js
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <h3 className="card-header">Vista de Mensaje</h3>

            <hr />
            <div className="card-body">
              <form>
                <div className="mb-3">
                  <label htmlFor="nombre" className="form-label">
                    Nombre:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="nombre"
                    name="nombre"
                    value={message.nombre}
                    readOnly // Para hacer el campo de solo lectura
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    name="email"
                    value={message.email}
                    readOnly
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="asunto" className="form-label">
                    Asunto:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="dni"
                    name="dni"
                    value={message.asunto}
                    readOnly
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="telefono" className="form-label">
                    Telefono:
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="telefono"
                    name="telefono"
                    value={message.telefono}
                    readOnly
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="message" className="form-label">
                    Mensaje:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="message"
                    name="message"
                    value={message.message}
                    readOnly
                  />
                </div>

                <div className="d-flex justify-content-between">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={Message}
                  >
                    <BsArrowLeft style={{ fontSize: "1.6em" }} /> Regresar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllMessage;
